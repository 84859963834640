import { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react'
import Asterisk from '../../../components/Asterisk'
import { AppLocation } from '../../location/locationSlice'
import { startSxpProxy } from '../../../utils/api'
import { ADMIN_PROJECT_ID } from '../../../utils/constants'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { toastOptions } from '../../lms/models'
import ToastMessage from '../../lms/components/ToastMessage'
import { Box, Button } from '@mui/material'
import { ErrorMessage, forms, locationIntent, messages } from '../constants'

const emptyLocation: AppLocation = {
  id: '',
  name: '',
  prefix: '',
  ipPrefix: '',
  value: '',
  address: '',
  email: '',
  phone: '',
  status: '',
  dlNumber: '',
  hasInventory: 'no',
  type: '',
  cluster: '',
  gst: '',
  ipd: 'no',
  pin: '',
  StreetNo: '',
  street: '',
  city: '',
  state: '',
  code: '',
}

type Props = {
  loc: AppLocation | null
}

const LocationForm = ({ loc }: Props) => {
  const [location, setLocation] = useState<AppLocation>(loc ?? emptyLocation)
  const navigate = useNavigate()
  const [cluster, setcluster] = useState([])
  const [type, setType] = useState([])
  const [isloading, setisLoading] = useState(false)

  const GetClusterAndType = async () => {
    try {
      const intent = 'getClusterandType'
      const resp = await startSxpProxy(ADMIN_PROJECT_ID, intent)
      if (resp?.data) {
        setcluster(resp?.data?.data?.clusters || resp?.data?.clusters)
        setType(resp?.data?.data?.facility_types || resp?.data?.facility_types)
      } else {
        setcluster([])
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    GetClusterAndType()
  }, [])
  const handleChange = (
    ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    const { name, type, value } = ev.target

    if (type === 'checkbox') {
      const isChecked = (ev.target as HTMLInputElement).checked
      setLocation((prev) => ({ ...prev, [name]: isChecked ? 'yes' : 'no' }))
    } else {
      setLocation((prev) => ({ ...prev, [name]: value }))
    }
  }

  const handleSubmit = (ev: FormEvent<HTMLFormElement>) => {
    ev.preventDefault()
    const gstRegex = /^[A-Z0-9]{15}$/
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    const phoneRegex = /^[0-9]{10}$/
    if (!/^[a-zA-Z ]+$/.test(location.name)) {
      toast.error(ErrorMessage?.CharactersCheck)
      return
    }
    if (!phoneRegex.test(location.phone)) {
      toast.error(ErrorMessage?.PhoneNumberCheck)
      return
    }
    if (!emailRegex.test(location.email)) {
      toast.error(ErrorMessage?.AddressCheck)
      return
    }
    if (!gstRegex.test(location?.gst)) {
      toast.error(ErrorMessage?.GSTCheck)
      return
    }

    const {
      name,
      prefix,
      email,
      phone,
      ipPrefix,
      dlNumber,
      hasInventory,
      cluster,
      type,
      gst,
      pin,
      street,
      StreetNo,
      city,
      state,
      code,
    } = location

    const constructedAddress = `${
      StreetNo ? StreetNo + ',' : ''
    }${street}, ${city}, ${state}, - ${pin}`
      ?.trim()
      ?.replace(/^,|,$/g, '')

    const payload = {
      id: loc?.id || '',
      locationName: name?.trim(),
      locationPrefix: prefix?.trim(),
      ipPrefix: ipPrefix?.trim(),
      address: constructedAddress,
      email,
      mobile: phone,
      status: loc?.status || 'active',
      dlNumber,
      hasInventory,
      type,
      gst,
      cluster,
      pin,
      street,
      StreetNo,
      city,
      state,
      code,
    }
    setisLoading(true)
    if (loc) {
      startSxpProxy(ADMIN_PROJECT_ID, locationIntent?.editLocation, payload)
        ?.then(() => {
          toast?.success(messages?.editedlocmsg, toastOptions)
          navigate('/administration/locations')
        })
        ?.catch(() => {
          toast.error(ErrorMessage?.editErrorMessage)
        })
    } else {
      startSxpProxy(ADMIN_PROJECT_ID, locationIntent?.createLocation, payload)
        ?.then(() => {
          toast?.success(ErrorMessage?.createdMessage, toastOptions)
          navigate('/administration/locations')
        })
        ?.catch((error) => {
          const errorMessage = error?.response?.data

          if (errorMessage) {
            const nameMatch = errorMessage?.match(
              /index: name_1 dup key: { : "(.*?)" }/i
            )
            const prefixMatch = errorMessage.match(
              /index: prefix_1 dup key: { : "(.*?)" }/i
            )
            let customMessage

            if (nameMatch) {
              customMessage = `Name ${nameMatch[1]} already exists.`
            } else if (prefixMatch) {
              customMessage = `Prefix ${prefixMatch[1]} already exists.`
            } else {
              customMessage = errorMessage
            }

            toast?.error(<ToastMessage message={customMessage} />, toastOptions)
          } else {
            toast?.error(
              <ToastMessage message={ErrorMessage?.createdEroorMessage} />,
              toastOptions
            )
          }
        })
        .finally(() => {
          setisLoading(false)
        })
    }
  }
  const patientTableContainerRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    const resizeHandler = () => {
      const container = patientTableContainerRef.current
      if (container) {
        const availableHeight: any =
          window.innerHeight - container.getBoundingClientRect().top
        container.style.maxHeight = `${availableHeight - 60}px`
      }
    }
    window.addEventListener('resize', resizeHandler)
    resizeHandler()
    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])
  const odooLocationTypes =
    process.env.REACT_APP_ODOO_LOCATION_TYPE?.split(',')?.map(Number) || []
  return (
    <form className='admin-form' onSubmit={handleSubmit}>
      <div className='admin-form-flex w-72' ref={patientTableContainerRef}>
        <div className='location-container'>
          <label htmlFor='prefix' className='location-input-label'>
            Prefix
            <Asterisk />
            &nbsp;:
          </label>
          <input
            required
            disabled={!!loc}
            name='prefix'
            type='text'
            placeholder='Enter Location Prefix'
            value={location?.prefix}
            onChange={handleChange}
            className='location-input-content'
          />
        </div>
        <div className='location-container'>
          <label htmlFor='name' className='location-input-label'>
            Operating Unit
            <Asterisk />
            &nbsp;:
          </label>
          <input
            required
            name='name'
            type='text'
            placeholder='Enter Location Name'
            value={location?.name}
            onChange={handleChange}
            className='location-input-content'
          />
        </div>
        <div className='location-container'>
          <label htmlFor='cluster' className='location-input-label'>
            Cluster
            <Asterisk />
            &nbsp;
          </label>
          <select
            name='cluster'
            value={location?.cluster}
            onChange={handleChange}
            className='bg-none'
          >
            <option value=''>Select</option>
            {cluster?.map((item: any) => (
              <option key={item?.id} value={item?.id}>
                {item?.name}
              </option>
            ))}
          </select>
        </div>
        <div className='location-container'>
          <label htmlFor='type' className='location-input-label'>
            Type
            <Asterisk />
            &nbsp;:
          </label>
          <select
            name='type'
            value={location?.type}
            onChange={handleChange}
            className='bg-none'
          >
            <option value='#'>Select</option>
            {type?.map((item: any) => (
              <option key={item?.id} value={item?.id}>
                {item?.name}
              </option>
            ))}
          </select>
        </div>
        {odooLocationTypes.includes(Number(location?.type)) && (
          <div className='location-container'>
            <label htmlFor='ipPrefix' className='location-input-label'>
              IPPrefix
              <Asterisk />
              &nbsp;:
            </label>
            <input
              required
              disabled={!!loc}
              name='ipPrefix'
              type='text'
              placeholder='Enter IPPrefix'
              value={location?.ipPrefix}
              onChange={handleChange}
              className='location-input-content'
            />
          </div>
        )}
        <div className='location-container'>
          <label htmlFor='ipPrefix' className='location-input-label'>
            Code
            <Asterisk />
            &nbsp;:
          </label>
          <input
            required
            name='code'
            type='text'
            placeholder='Enter Code'
            value={location?.code}
            onChange={handleChange}
            className='location-input-content'
          />
        </div>

        <div className='location-container'>
          <label htmlFor='doorNo' className='location-input-label'>
            Street No
            <Asterisk />
            &nbsp;:
          </label>
          <input
            name='StreetNo'
            type='text'
            value={location?.StreetNo}
            placeholder='Enter street No'
            onChange={handleChange}
          />
        </div>
        <div className='location-container'>
          <label htmlFor='street' className='location-input-label'>
            Street
            <Asterisk />
            &nbsp;:
          </label>
          <input
            name='street'
            type='text'
            value={location?.street}
            placeholder='Enter Street'
            onChange={handleChange}
          />
        </div>
        <div className='location-container'>
          <label htmlFor='city' className='location-input-label'>
            City
            <Asterisk />
            &nbsp;:
          </label>
          <input
            name='city'
            type='text'
            value={location?.city}
            placeholder='Enter City'
            onChange={handleChange}
          />
        </div>
        <div className='location-container'>
          <label htmlFor='doorNo' className='location-input-label'>
            State
            <Asterisk />
            &nbsp;:
          </label>
          <input
            name='state'
            type='text'
            value={location?.state}
            placeholder='Enter State'
            onChange={handleChange}
          />
        </div>
        <div className='location-container'>
          <label htmlFor='pin' className='location-input-label'>
            PIN
            <Asterisk />
            &nbsp;:
          </label>
          <input
            name='pin'
            type='text'
            value={location?.pin}
            placeholder='Enter PIN'
            onChange={handleChange}
          />
        </div>

        <div className='location-container'>
          <label htmlFor='phone' className='location-input-label'>
            Phone Number
            <Asterisk />
            &nbsp;:
          </label>
          <input
            required
            name='phone'
            type='text'
            placeholder='Enter Phone Number'
            value={location?.phone}
            onChange={handleChange}
            className='location-input-content'
          />
        </div>
        <div className='location-container'>
          <label htmlFor='email' className='location-input-label'>
            Email
            <Asterisk />
            &nbsp;:
          </label>
          <input
            required
            name='email'
            type='text'
            placeholder='Enter Email'
            value={location?.email}
            onChange={handleChange}
            className='location-input-content'
          />
        </div>
        <div className='location-container'>
          <label htmlFor='gst' className='location-input-label'>
            GST No
            <Asterisk />
            &nbsp;:
          </label>
          <input
            required
            name='gst'
            type='text'
            placeholder='Enter GST No'
            value={location?.gst}
            onChange={handleChange}
            className='location-input-content'
            maxLength={15}
          />
        </div>
        <div className='location-container'>
          <label htmlFor='dlNumber' className='location-input-label'>
            DL No
            <Asterisk />
            &nbsp;:
          </label>
          <input
            required
            name='dlNumber'
            type='text'
            placeholder='Enter DL No'
            value={location?.dlNumber}
            onChange={handleChange}
            className='location-input-content'
          />
        </div>
        <div className='location-container'>
          <label htmlFor='hasInventory' className='location-input-label'>
            Inventory:
          </label>
          <input
            type='checkbox'
            name='hasInventory'
            checked={location?.hasInventory === 'yes'}
            onChange={handleChange}
            className='location-input-content'
          />
        </div>
        <div className='location-container'>
          <div className='location-input-label'></div>
          <div className='location-input-content'>
            <Box className='box-btn-location'>
              <Button
                type='submit'
                variant='contained'
                color='primary'
                className='submit-btn-location'
                disabled={isloading}
              >
                {isloading ? forms?.Submitting : forms?.submit}
              </Button>
            </Box>
          </div>
        </div>
      </div>
    </form>
  )
}

export default LocationForm
