import { ODOOCOGNITIVECARE_URL } from './Endpoints'

export const DMS_CLIENT_ID = process.env.REACT_APP_DMS_CLIENT_ID || ''
export const DMS_CLIENT_SECRET = process.env.REACT_APP_DMS_CLIENT_SECRET || ''
export const DMS_USERNAME = process.env.REACT_APP_DMS_USERNAME || ''
export const DMS_PASSWORD = process.env.REACT_APP_DMS_PASSWORD || ''
export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL
export const SOCKET_PATH = process.env.REACT_APP_SOCKET_PATH
export const FILE_SERVER_URL = process.env.REACT_APP_CHAT_FILE_SERVER_URL
export const AUTH_SERVER_URL = process.env.REACT_APP_AUTH_SERVER_URL
export const REALM = process.env.REACT_APP_REALM ?? ''
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID ?? ''
export const CHAT_PROJECT_ID = process.env.REACT_APP_CHAT_PROJECT_ID
export const REGISTRATION_PROJECT_ID =
  process.env.REACT_APP_REGISTRATION_PROJECT_ID
export const OPD_PROJECT_ID = process.env.REACT_APP_OPD_PROJECT_ID
export const LABS_PROJECT_ID = process.env.REACT_APP_LABS_PROJECT_ID
export const MEMBERSHIP_PROJECT_ID = process.env.REACT_APP_MEMBERSHIP_PROJECT_ID
export const ADMIN_PROJECT_ID = process.env.REACT_APP_ADMIN_PROJECT_ID
export const RADIOLOGY_PROJECT_ID = process.env.REACT_APP_RADIOLOGY_PROJECT_ID
export const IPD_PROJECT_ID = process.env.REACT_APP_IPD_PROJECT_ID
export const PACKAGE_PROJECT_ID = process.env.REACT_APP_PACKAGE_PROJECT_ID
export const AB_MEMBERHSIP_PROJECT_ID = process.env.REACT_APP_AB_MEMBERSHIP_ID
export const ODOO_URL = process.env.REACT_APP_ODOO_URL
export const REPORT_URL = process.env.REACT_APP_REPORT_URL ?? ''
export const INNOMAINT_URL = process.env.REACT_APP_INNOMAINT_URL
export const INNOMAINT_URL_QR_CODE =
  process.env.REACT_APP_INNOMAINT_URL_QR_CODE ?? ''
export const KIMS_LOCATION_ID = process.env.REACT_APP_KIMS_LOCATION_ID
export const CRITICAL_BED_ID = process.env.REACT_APP_CRITICAL_BED_ID
export const NON_CRITICAL_BED_ID =
  process.env.REACT_APP_NON_CRITICAL_BED_ID ?? ''
export const COMMANDCENTER_URL = process.env.REACT_APP_COMMANDCENTER_URL ?? ''
export const RMS_URL = process.env.REACT_APP_RMS_URL ?? ''
export const MEDUNITED_AWGMENT_GATEWAY =
  process.env.REACT_APP_MEDUNITED_AWGMENT_GATEWAY
export const ODOO_COGNITIVECARE = `${process.env.REACT_APP_ODOO_COGNITIVECARE}${ODOOCOGNITIVECARE_URL}`

export const NEW_LINE_CHARACTER = '\n'
export const NEW_LINE_REPLACE = '*#*'
export const VERSION = 'WORKING'
export const upload = 'Upload'
export const uploadImageText = 'Please upload image to get url'
export const linkCopyText = 'Please click on the link to copy it.'
export const enterMobileNumber = 'Enter Mobile No. To Send Report On Whatsapp:'
export const sendText = 'Send'
export const notificationUrl = `${process.env.REACT_APP_MEDUNITED_AWGMENT_GATEWAY}${process.env.REACT_APP_REPORT_NOTIFICATION_ENDPOINT}`
export const downloadUrl = `${process.env.REACT_APP_MEDUNITED_AWGMENT_GATEWAY}${process.env.REACT_APP_DMS_DOWNLOAD_ENDPOINT}${process.env.REACT_APP_REALM}?id=`
export const trimPDF = {
  footerTextOne: '1. This Is An Electronically Authenticated Report.',
  footerTextTwo:
    '2. Please Correlate With Clinical Findings, Consult a Doctor For Discussion & Further Medication',
  uploadPdfText: 'Upload PDF',
  selectSourceText: 'Select Source:',
  kimsText: 'KIMS',
  tenetText: 'Tenet',
  gayatriText: 'Gayatri Labs',
  withHeaderAndFooterText: 'With Header & Footer',
  withoutHeaderAndFooterText: 'Without Header & Footer',
  trimAndDownloadText: 'Trim & Download',
  selectOption: 'Select An Option',
}
export const indianPhoneCode = '+91'
export const indianLangCode = 'en'
export const whatsappOperationName = 'SEND_WHATSAPP_MESSAGE'
export const smsOperationName = 'SEND_SMS'
export const dispatchText = 'dispatch'
export const communicationMode = {
  whatsapp: 'whatsapp',
  sms: 'sms',
  none: 'none',
}
export const whatsappStatus = {
  delivered: 'DELIVERED',
  sent: 'SENT',
  read: 'READ',
}
export const REACT_APP_EMP360 = process.env.REACT_APP_EMP360
export const suiteCRM = process.env.REACT_APP_SUITE_CRM
