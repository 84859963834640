import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import '../membership/membership.scss'
import { IconButton } from '@mui/material'
import { Patient } from 'fhir/r4'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useAppSelector } from '../../app/hooks'
import { startSxpProxy } from '../../utils/api'
import { CHAT_PROJECT_ID, MEMBERSHIP_PROJECT_ID } from '../../utils/constants'
import ToastMessage from '../lms/components/ToastMessage'
import { toastOptions } from '../lms/models'
import { makeName } from '../lms/utils'
import { selectSelectedLocationId } from '../location/locationSlice'
import { SearchPatient } from '../membership/models'
import SelectableSearch from '../OPDScreen/selectedtableseacrh'
import ListOfABMembers from './ListOfABMembers'
import SearchPatientList from './searchPatientList'
import { formValues } from '../administration/constants'
import { removePatient } from '../Radiology/Constants/constants'
import {
  ABMembershipIntent,
  IDENTIFIER,
  NAME,
  PHONE,
  Status,
} from './constants'
const CreateABMembership = () => {
  const location = useAppSelector(selectSelectedLocationId)
  const navigate = useNavigate()
  const [show, setShow] = useState<boolean>(false)
  const [patients, setPatients] = useState<SearchPatient[]>([])
  const [members, setMembers] = useState<any[]>([])
  const [relation] = useState<string>('')

  const formatPatients = (entry: { resource: Patient }[]): SearchPatient[] => {
    return (
      entry?.map((en) => {
        const gender = en?.resource?.gender
        const pat: SearchPatient = {
          id: en?.resource?.id ?? '',
          name: makeName(en?.resource?.name),
          gender:
            gender === `${formValues?.male}`
              ? 'Male'
              : gender === 'female'
              ? 'Female'
              : 'Others',
          dob: en.resource.birthDate ?? '',
          uhid: en.resource?.identifier?.[2]?.value ?? '',
          thopId: en.resource?.identifier?.[0]?.value ?? '',
          isSelected: false,
          isNominee: false,
          registration: en.resource?.identifier?.[3]?.value ?? '',
        }
        return pat
      }) ?? []
    )
  }
  const handleSearch = (query: string, param: string) => {
    if (!query) {
      setPatients([])
      setShow(false)
      return
    }
    setShow(true)
    const intent = 'getPatientsAPI'
    let q = ''
    switch (query) {
      case 'ID':
        q = IDENTIFIER
        break
      case 'Phone':
        q = PHONE
        break
      case 'Name':
        q = NAME
        break
    }
    const state = {
      pageSize: 20,
      query: q,
      value: param ? param : undefined,
    }
    startSxpProxy(CHAT_PROJECT_ID, intent, state)
      ?.then((data) => {
        if (data?.data?.entry?.length) {
          setPatients(formatPatients(data?.data?.entry))
        } else {
          setPatients([])
        }
      })
      ?.catch((err) => {
        console?.log(err)
      })
  }

  const handleMemberSelect = (id: string, relation: string) => {
    const selectedMember = patients?.find((member) => member?.id === id)
    if (selectedMember) {
      const newMember: any = {
        ...selectedMember,
        relation,
      }
      if (members?.some((m) => m?.id === newMember?.id)) {
        toast(<ToastMessage message={'Member already added.'} />, {
          ...toastOptions,
          type: 'error',
        })
        return
      }
      startSxpProxy(
        MEMBERSHIP_PROJECT_ID,
        ABMembershipIntent?.membershipLines,
        {
          location,
          partner: selectedMember?.uhid,
        }
      )
        ?.then((data) => {
          const memberships: any[] = data?.data?.data ?? []
          const hasConflictingMembership = memberships?.some((item) =>
            [Status?.waiting, Status?.invoiced, Status?.paid]?.includes(
              item?.state
            )
          )
          if (!hasConflictingMembership) {
            setMembers((prevMembers) => [...prevMembers, newMember])
          } else {
            toast(
              <ToastMessage message={'Already available in membership.'} />,
              {
                ...toastOptions,
                type: 'error',
              }
            )
          }
        })
        ?.catch((err) => {
          console?.error(err)
          toast(
            <ToastMessage
              message={err?.response?.data?.error || 'Error occurred'}
            />,
            {
              ...toastOptions,
              type: 'error',
            }
          )
        })
    }
  }

  const handleMemberUnSelect = (id: string) => {
    const member = members.filter((d) => d.id !== id)
    setMembers(member)
  }

  const handlePatient = () => {
    navigate('/patient-registration')
  }

  const handleMembership = () => {
    navigate('/ab/membership')
  }

  return (
    <div className='layoutcontent'>
      <div className='overallContainer'>
        <div>
          <IconButton onClick={handleMembership}>
            <KeyboardBackspaceIcon className='backspace-icon-membership-view' />
          </IconButton>
        </div>
        <div className='flexDiv'>
          <SelectableSearch
            items={['ID', 'Phone', 'Name']}
            onSearch={handleSearch}
            thopId={undefined}
          />
          <button
            className={
              show && patients.length === 0
                ? 'btn btn-primaryBtn'
                : 'disabled-button'
            }
            onClick={handlePatient}
            disabled={!(show && patients.length === 0)}
          >
            {removePatient}
          </button>
        </div>
      </div>
      <div className='mb10'></div>
      {(members?.length > 0 || show) && (
        <ListOfABMembers
          members={members}
          onMemberUnSelect={handleMemberUnSelect}
        />
      )}
      {show && (
        <SearchPatientList
          patients={patients}
          onMemberSelect={handleMemberSelect}
          members={members}
          relation={relation}
        />
      )}
    </div>
  )
}

export default CreateABMembership
