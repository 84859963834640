import { useRef, useEffect, useState } from 'react'
import { getAgeInYears } from '../../utils/dateUtils'
import { SearchPatient, MemberPatient } from '../membership/models'
// import { toast } from 'react-toastify'
import { startSxpProxy } from '../../utils/api'
import {
  AB_MEMBERHSIP_PROJECT_ID,
  CHAT_PROJECT_ID,
} from '../../utils/constants'
// import ToastMessage from '../lms/components/ToastMessage'
// import { toastOptions } from '../lms/models'
import {
  ABMembershipIntent,
  addrelation,
  Cancel,
  changeRegType,
  Confirm,
  resize,
  SearchResult,
  selectRelation,
} from './constants'
import { tableText } from '../administration/constants'
import {
  noPatientsFound,
  radiologyButtons,
  toastOptions,
} from '../Radiology/Constants/constants'
import { toast } from 'react-toastify'
import ToastMessage from '../lms/components/ToastMessage'

type Props = {
  patients: SearchPatient[]
  onMemberSelect: (id: string, relation: string) => void
  members: MemberPatient[]
  relation: any
}

const SearchPatientList = ({ patients, onMemberSelect, members }: Props) => {
  const patientsTableContainerRef = useRef<HTMLDivElement | null>(null)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [selectedPatient, setSelectedPatient] = useState<SearchPatient | null>(
    null
  )
  const [relations, setRelations] = useState<string[]>([])
  const [selectedRelation, setSelectedRelation] = useState<string>('')
  useEffect(() => {
    const resizeHandler = () => {
      const container = patientsTableContainerRef.current
      if (container) {
        const availableHeight =
          window.innerHeight - container.getBoundingClientRect().top
        container.style.maxHeight = `${availableHeight - 25}px`
      }
    }
    window.addEventListener(`${resize}`, resizeHandler)
    resizeHandler()
    return () => {
      window.removeEventListener(`${resize}`, resizeHandler)
    }
  }, [])

  const isAdded = (id: string): boolean =>
    members?.findIndex((m) => m?.id === id) !== -1

  const handleOpenDialog = (patient: SearchPatient) => {
    setSelectedPatient(patient)
    setDialogOpen(true)
  }
  const handleAddMember = async () => {
    try {
      const resp = await startSxpProxy(
        CHAT_PROJECT_ID,
        ABMembershipIntent?.checkABMember,
        {
          patientIdentifier: selectedPatient?.uhid,
        }
      )
      const data = resp?.data
      if (Array?.isArray(data)) {
        setRelations(data?.map((item: any) => item?.value ?? ''))
      }
      if (selectedPatient?.id) {
        const memberId = selectedPatient.id
        onMemberSelect(memberId, selectedRelation)
      }
      setDialogOpen(false)
      setSelectedRelation('')
    } catch (error: any) {
      toast(
        <ToastMessage
          message={error?.response?.data?.error ?? `${changeRegType}`}
        />,
        {
          ...toastOptions,
          type: 'error',
        }
      )
    }
  }

  const relationsData = () => {
    startSxpProxy(AB_MEMBERHSIP_PROJECT_ID, ABMembershipIntent?.relations)
      ?.then((resp) => {
        const data = resp?.data
        if (Array?.isArray(data)) {
          setRelations(data?.map((item: any) => item?.value ?? ''))
        }
      })
      .catch((err) => {
        console?.error(err)
      })
  }
  useEffect(() => {
    relationsData()
  }, [])
  return (
    <>
      {dialogOpen && (
        <div className='dialog'>
          <div className='dialog-content'>
            <h3>{addrelation}</h3>
            <select
              className='relation-dropdown'
              onChange={(e) => setSelectedRelation(e?.target?.value)}
              value={selectedRelation}
            >
              <option value=''>{selectRelation}</option>
              {relations?.map((rel, index) => (
                <option key={index} value={rel}>
                  {rel}
                </option>
              ))}
            </select>
            <div className='dialog-actions'>
              <button onClick={handleAddMember} disabled={!selectedRelation}>
                {Confirm}
              </button>
              <button onClick={() => setDialogOpen(false)}>{Cancel}</button>
            </div>
          </div>
        </div>
      )}
      <div className='search-table'>
        <p className='admin-header'>{SearchResult}</p>
      </div>

      <div className='fixedTableHeads' ref={patientsTableContainerRef}>
        <table className='data-table table-fixed admin-table'>
          <thead className='admin-table-head sticky'>
            <tr>
              <th className='text-left table-w-15'>{tableText?.MUHID}</th>
              <th className='text-left table-w-30'>{tableText?.name}</th>
              <th className='text-center table-w-6'>{tableText?.gender}</th>
              <th className='text-center table-w-8'>{tableText?.age}</th>
              <th className='text-center table-w-6'>{tableText?.Relation}</th>
              <th className='text-center table-w-6'>{tableText?.action}</th>
            </tr>
          </thead>
          <tbody>
            {patients?.length > 0 ? (
              patients?.map((d: SearchPatient, i) => (
                <tr className='memberId' key={d?.id}>
                  <td>{i + 1}</td>
                  <td>{d?.uhid || d?.thopId}</td>
                  <td className='selected-name'>{d?.name} </td>
                  <td className='text-center'>{d?.gender}</td>
                  <td className='text-center'>
                    {getAgeInYears(d?.dob) ?? '-'}
                  </td>
                  <td className='text-center'>
                    <button
                      className={
                        isAdded(d.id) ? 'disabled-button' : 'typeButton'
                      }
                      onClick={() => handleOpenDialog(d)}
                      disabled={isAdded(d.id)}
                    >
                      {radiologyButtons?.add}
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6} className='text-center'>
                  {noPatientsFound}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default SearchPatientList
