import { toast } from 'react-toastify'
import {
  AmbulanceDetails,
  BedDetails,
  constants,
  endpoint,
  mappingStatus,
  messages,
} from './Constants'

export const addProrithmDevice = (deviceId: string) => {
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  myHeaders.append(
    'Authorization',
    `Bearer ${sessionStorage.getItem('react-token')}`
  )

  const payload = {
    prorithmDeviceId: deviceId,
    type: mappingStatus?.notMapped,
  }

  const requestOptions: RequestInit = {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify(payload),
    redirect: 'follow',
  }

  fetch(
    `${process.env.REACT_APP_API_GATEWAY_URL}${endpoint?.addDevice}`,
    requestOptions
  )
    .then((response) => {
      if (!response?.ok)
        throw new Error('Failed to add Device. or Device already exists.')
      return response?.json()
    })
    .then((result) => {
      if (result?.success) {
        toast(messages?.deviceAddedSuccessfully, {
          position: 'bottom-center',
          hideProgressBar: true,
          closeButton: false,
          theme: 'dark',
          autoClose: 4000,
        })
      } else {
        toast(messages?.failedToAddDevice, {
          position: 'bottom-center',
          hideProgressBar: true,
          closeButton: false,
          theme: 'dark',
          autoClose: 4000,
        })
      }
    })
    .catch((error) => {
      console.error(error)
      toast(messages?.failedToAddDevice, {
        position: 'bottom-center',
        hideProgressBar: true,
        closeButton: false,
        theme: 'dark',
        autoClose: 4000,
      })
    })
}

export const editProrithmMapping = async (
  mapTo: string,
  deviceId: string,
  location: string,
  bedDetails?: BedDetails,
  ambulanceDetails?: AmbulanceDetails
) => {
  console.log(mapTo, deviceId, location, bedDetails, ambulanceDetails)

  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  myHeaders.append(
    'Authorization',
    `Bearer ${sessionStorage.getItem('react-token')}`
  )

  const mapToLocationPayload = {
    locationName: location,
    prorithmDeviceId: deviceId,
    type: constants?.location,
    ambulanceNumber: '',
    driverPhoneNumber: '',
    emtNumber: '',
    ambulanceStatus: '',
    wardName: '',
    wardId: '',
    roomName: '',
    roomId: '',
    bedName: '',
    bedId: '',
  }

  const mapToBedPayload = bedDetails
    ? {
        wardId: bedDetails?.wardId,
        wardName: bedDetails?.wardName,
        roomId: bedDetails?.roomId,
        roomName: bedDetails?.roomName,
        bedId: bedDetails?.bedId,
        bedName: bedDetails?.bedName,
        locationName: location,
        prorithmDeviceId: deviceId,
        type: constants?.bed,
        ambulanceNumber: '',
        driverPhoneNumber: '',
        emtNumber: '',
        ambulanceStatus: '',
      }
    : null

  const mapToAmbulancePayload = ambulanceDetails
    ? {
        prorithmDeviceId: deviceId,
        type: mappingStatus?.ambulance,
        locationName: location,
        ambulanceNumber: ambulanceDetails?.ambulanceNumber,
        driverPhoneNumber: ambulanceDetails?.driverPhone,
        emtNumber: ambulanceDetails?.emtPhone,
        ambulanceStatus: ambulanceDetails?.status,
        wardName: '',
        wardId: '',
        roomName: '',
        roomId: '',
        bedName: '',
        bedId: '',
      }
    : null

  let payload
  if (mapTo === constants?.location) {
    payload = mapToLocationPayload
  } else if (mapTo === constants?.ambulanceText && mapToAmbulancePayload) {
    payload = mapToAmbulancePayload
  } else if (mapTo === constants?.bed && mapToBedPayload) {
    payload = mapToBedPayload
  } else {
    console.error('Invalid mapping type or missing required details')
    return false
  }

  const requestOptions: RequestInit = {
    method: 'PUT',
    headers: myHeaders,
    body: JSON.stringify(payload),
    redirect: 'follow',
  }

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_GATEWAY_URL}${endpoint?.editProrithmMapping}`,
      requestOptions
    )
    if (!response?.ok) throw new Error('Failed to map Device.')
    const data = await response?.json()
    return data?.success
  } catch (error) {
    console.error(error)
    toast(messages?.mappingAlreadyExistsError, {
      position: 'bottom-center',
      hideProgressBar: true,
      closeButton: false,
      theme: 'dark',
      autoClose: 4000,
    })
    return false
  }
}

export const getAllProrithmDevices = async (page: number, pageSize: number) => {
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  myHeaders.append(
    'Authorization',
    `Bearer ${sessionStorage.getItem('react-token')}`
  )

  const requestOptions: RequestInit = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  }

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_GATEWAY_URL}${endpoint?.getAllDevices}?page=${page}&size=${pageSize}`,
      requestOptions
    )
    if (!response?.ok) throw new Error('Failed to get all devices.')
    const result = await response?.json()
    return result?.data ?? []
  } catch (error) {
    console.error(error)
    toast(messages?.getAllDevicesError, {
      position: 'bottom-center',
      hideProgressBar: true,
      closeButton: false,
      theme: 'dark',
      autoClose: 4000,
    })
    return []
  }
}

export const checkDeviceMappingStatus = async (deviceId: string) => {
  const myHeaders = new Headers()
  myHeaders.append(
    'Authorization',
    `Bearer ${sessionStorage.getItem('react-token')}`
  )

  const requestOptions: RequestInit = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  }

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_GATEWAY_URL}${endpoint?.checkDeviceMapping}${deviceId}`,
      requestOptions
    )
    if (!response?.ok) throw new Error('Failed to get device mapping status.')
    const data = await response?.json()
    return data?.data ?? ''
  } catch (error) {
    console.error(error)
    toast(messages?.checkMappingStatusError, {
      position: 'bottom-center',
      hideProgressBar: true,
      closeButton: false,
      theme: 'dark',
      autoClose: 4000,
    })
  }
}

export const deleteProrithmDevice = async (deviceId: string) => {
  const myHeaders = new Headers()
  myHeaders.append(
    'Authorization',
    `Bearer ${sessionStorage.getItem('react-token')}`
  )
  const requestOptions: RequestInit = {
    method: 'DELETE',
    headers: myHeaders,
    redirect: 'follow',
  }

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_GATEWAY_URL}${endpoint?.deleteDevice}${deviceId}`,
      requestOptions
    )
    if (!response?.ok) throw new Error('Failed to delete Device.')
    const data = await response?.json()
    return data?.success
  } catch (error) {
    console.error(error)
    toast(messages?.deleteDeviceFailed, {
      position: 'bottom-center',
      hideProgressBar: true,
      closeButton: false,
      theme: 'dark',
      autoClose: 4000,
    })
  }
}

export const getAmbulanceDetails = async (deviceId: string) => {
  const myHeaders = new Headers()
  myHeaders.append(
    'Authorization',
    `Bearer ${sessionStorage.getItem('react-token')}`
  )
  const requestOptions: RequestInit = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  }

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_GATEWAY_URL}${endpoint?.ambDetails}${deviceId}`,
      requestOptions
    )
    if (!response?.ok) throw new Error('Failed to get ambulance Details.')
    const data = await response?.json()
    return data?.data
  } catch (error) {
    console.error(error)
    toast(messages?.ambDetailsError, {
      position: 'bottom-center',
      hideProgressBar: true,
      closeButton: false,
      theme: 'dark',
      autoClose: 4000,
    })
  }
}
