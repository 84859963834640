import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { getTotalAge, readableDateFormat } from '../../utils/dateUtils'
import { startSxpProxy } from '../../utils/api'
import {
  AB_MEMBERHSIP_PROJECT_ID,
  ADMIN_PROJECT_ID,
  MEMBERSHIP_PROJECT_ID,
} from '../../utils/constants'
import { useAppSelector } from '../../app/hooks'
import { selectSelectedLocationId } from '../location/locationSlice'
import UserImg from '../../assets/images/user.png'
// import '../membership.scss'
import { makeName } from '../lms/utils'
import MaskedNumber from '../../components/MaskedNumber'
import { odooPharmacyLink } from '../appointments/models'
import IconButton from '@mui/material/IconButton'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import { ABMember, itemsArray, MembershipModel } from '../membership/models'
import { capitalizeFirstLetter, openUrlFromApi } from '../patients/utils'
import {
  ABMembershipIntent,
  Categories,
  InvoiceLink,
  InvoiceList,
  noBenefits,
  NoMemberinFHIR,
  Products,
} from './constants'
import { loadingText } from '../Radiology/Constants/constants'
import { tableText } from '../administration/constants'
import { ABMemebrship } from '../bedManagement/endpoints/EndPoints'

type Benefit = {
  innerKey: string
  quantity: any
  allowedCount: any
  discountPrice: any
  category: any
  categoryId: any
}
type Invoice = {
  innerKey: string
  id: string
  uhid: string
  invoices: any
}
type Item = {
  allowedCount: number
  category: boolean
  categoryId: string
  discountPrice: string
  innerKey: string
  quantity: number
}

type ItemsObject = {
  [key: string]: Item[]
}

const ABMembershipDetails = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [membershipDetail, setMembershipDetail] = useState<MembershipModel[]>(
    []
  )
  const [benefits, setBenefits] = useState<ItemsObject>({})
  const [invoices, setInvoices] = useState<Invoice[]>([])
  const [member, setMember] = useState<ABMember[]>([])
  const [loading, setLoading] = useState(false)
  const location = useAppSelector(selectSelectedLocationId)
  const sortedKeys = Object.keys(benefits).sort()
  const sortedBenefits = {} as { [key: string]: any }
  sortedKeys?.forEach((key) => {
    const obj = benefits[key]
    obj.sort((a: any, b: any) =>
      a?.innerKey?.split(':')?.[1]?.toLowerCase() <
      b?.innerKey?.split(':')?.[1]?.toLowerCase()
        ? -1
        : 1
    )
    sortedBenefits[key] = obj
  })

  function isEmpty(obj: any) {
    for (const prop in obj) {
      if (Object.hasOwn(obj, prop)) {
        return false
      }
    }

    return true
  }

  const fetchMembershipDetails = async (id: any, location: string) => {
    try {
      const data: any = await startSxpProxy(
        AB_MEMBERHSIP_PROJECT_ID,
        ABMembershipIntent?.abMembershipLine,
        {
          location,
          lineId: id,
        }
      )
      const fetchedMembershipDetail = data?.data
      setMembershipDetail(fetchedMembershipDetail)
      const uhid = fetchedMembershipDetail?.[0]?.partner?.ref
      const associatedUhid = fetchedMembershipDetail?.[0]?.partner_ids?.map(
        (e: { ref: any }) => e.ref
      )
      const memberIds: any[] = []
      memberIds?.push(fetchedMembershipDetail?.[0]?.date)
      if (fetchedMembershipDetail?.[0]?.membership_partner_ids) {
        for (const element of fetchedMembershipDetail) {
          const membershipPartnerIds = element?.membership_partner_ids || []
          for (const partnerId of membershipPartnerIds) {
            if (associatedUhid?.includes(partnerId?.partner_id.ref)) {
              memberIds?.push(partnerId?.activation_date)
            }
          }
        }
      }
      const memberUhidsArray = [uhid, ...associatedUhid]?.filter((ua) => !!ua)
      const membersOne = memberUhidsArray?.join(',')
      const state = { patientIdentifier: membersOne }
      const patientData: any = await startSxpProxy(
        MEMBERSHIP_PROJECT_ID,
        ABMembershipIntent?.getPatientById,
        state
      )
      if (patientData?.data?.data?.total > 0) {
        const memberDetails = patientData?.data?.data?.entry?.map(
          (e: { resource: any }) => e?.resource
        )
        const uhidObj = memberDetails.reduce(
          (acc: any, cur: { identifier: { value: any }[] }) => {
            const uhid = cur.identifier?.[2]?.value
            return { ...acc, [uhid]: cur }
          },
          {}
        )
        const memberDetailArray = memberUhidsArray?.map((d, i) => {
          const role = uhidObj[d]
          const relation =
            fetchedMembershipDetail?.[0]?.membership_partner_ids?.find(
              (partner: any) =>
                partner?.partner_id?.ref === role?.identifier?.[2]?.value
            )?.relation_with_primary_partner

          return {
            names: makeName(role?.name),
            id: role?.id,
            primary: i === 0,
            age: getTotalAge(new Date(role?.birthDate)),
            date: role?.identifier?.[3]?.period?.start
              ? readableDateFormat(
                  new Date(role?.identifier?.[3]?.period?.start)
                )
              : '-',
            memberDate: memberIds?.[i]
              ? readableDateFormat(new Date(memberIds[i]))
              : '-',
            uhid: role?.identifier?.[2]?.value || '-',
            mobile: role?.telecom?.[0]?.value || '-',
            relation_with_primary_partner: relation || '',
          }
        })
        setMember(memberDetailArray)
      } else {
        setMember([])
      }
    } catch (error) {
      console?.error(error)
      setMember([])
    }
  }
  useEffect(() => {
    setLoading(true)
    fetchMembershipDetails(id, location)
  }, [id, location])

  const handleBack = () => {
    navigate(ABMemebrship)
  }
  const stringFunction = (d: string) => {
    const parts = d?.split(': ')
    if (parts?.length === 2) {
      return parts?.[1]?.trim()
    }
  }
  const fetchBenefitsAndInvoices = async (id: any, location: string) => {
    try {
      const data: any = await startSxpProxy(
        MEMBERSHIP_PROJECT_ID,
        ABMembershipIntent?.benefits,
        {
          location,
          id,
        }
      )
      const benefits = data?.data?.benefits || data?.benefits
      const invoices = data?.data?.invoices || data?.invoices

      for (const outerKey in benefits) {
        const innerObject = benefits[outerKey]
        for (const innerKey in innerObject) {
          const item = {
            innerKey: innerKey,
            quantity: innerObject[innerKey]?.quantity ?? '-',
            allowedCount: innerObject[innerKey]?.allowed_count ?? '-',
            discountPrice: innerObject[innerKey]?.disc_percent ?? '-',
            category: innerObject[innerKey]?.category,
            categoryId: innerObject[innerKey]?.categ_id,
          }
          itemsArray?.push(item)
        }
      }

      const itemsMap = itemsArray?.map((e) => {
        const item = []
        if (e?.category === false) {
          const cat = e?.categoryId
            ? e?.categoryId?.split('/')?.[0]?.trim()
            : e?.categoryId
          item?.push(cat)
        }
        return item
      })

      const filterItems = itemsMap?.flat()?.filter((item) => item?.length > 0)
      const unique = filterItems?.filter((value, index, self) => {
        return self?.indexOf(value) === index
      })
      const uniqueArray = unique?.sort()
      const reduceData = itemsArray?.reduce(
        (acc: Record<string, Benefit[]>, cur) => {
          if (cur.category) {
            const uniqueKey = cur.innerKey?.split(':')[1]?.split('/')[0]?.trim()
            if (!uniqueArray?.includes(uniqueKey)) {
              if (acc[Categories]) {
                acc[Categories]?.push(cur)
              } else {
                acc[Categories] = [cur]
              }
            }
          }
          let catId
          catId = cur?.categoryId
            ? cur?.categoryId?.split('/')?.[0]?.trim()
            : cur?.categoryId
          if (catId === undefined) {
            catId = cur?.innerKey?.split(':')?.[1]?.split('/')?.[0]?.trim()
          }

          if (uniqueArray.includes(catId)) {
            if (acc[catId]) {
              acc[catId]?.push(cur)
            } else {
              acc[catId] = [cur]
            }
          }

          return acc
        },
        {}
      )
      setBenefits(reduceData)
      if (invoices && Object?.keys(invoices)?.length > 0) {
        const invoicesArray = []
        for (const outKey in invoices) {
          const innerObject = invoices[outKey]
          for (const innerKey in innerObject) {
            const item = {
              innerKey: innerKey,
              id: innerObject?.[innerKey]?.id,
              uhid: innerObject?.[innerKey]?.uhid,
              invoices: innerObject?.[innerKey]?.invoices,
            }
            invoicesArray?.push(item)
          }
        }
        setInvoices(invoicesArray)
      } else {
        setInvoices([])
      }
    } catch (err) {
      console.error(err, 'error')
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    setLoading(true)
    fetchBenefitsAndInvoices(id, location)
  }, [id, location])

  const redirectPage = (id: any) => {
    openUrlFromApi(ADMIN_PROJECT_ID, odooPharmacyLink, {
      invoiceId: id,
      location: location,
    })
  }

  return (
    <>
      <div className='typeOne'>
        <div className='dashboard-container'>
          <div>
            <IconButton onClick={handleBack} className='membership-icon-btn'>
              <KeyboardBackspaceIcon className='backspace-icon-membership-view' />
            </IconButton>
          </div>
          <div className='type'>
            <div className='typeCard'>
              <span className='typeCardOne'>
                {membershipDetail?.[0]?.membership_id?.[1].toLowerCase()}
              </span>
            </div>

            <div className='dateCard'>
              <span className='dateCardOne'>
                Created {membershipDetail?.[0]?.date_from}
              </span>
              <span className='dateCardOne'>
                Valid Till {membershipDetail?.[0]?.date_to}
              </span>
              <span className='wallet'>
                Wallet Balance:{membershipDetail?.[0]?.credits}.00
              </span>
            </div>
          </div>
        </div>
        <div className=''>
          <table className='data-table admin-table table-fixed'>
            <thead>
              <tr>
                <th className='width-minor'>Sl No</th>
                <th>Members</th>
                <th className='text-center'>UHID</th>
                <th className='text-center'>Mobile No.</th>
                <th className='text-center'>Registered Date</th>
                <th className='text-center'>Membership Date</th>
                <th className='text-center'>Preference</th>
              </tr>
            </thead>
            <tbody>
              {member?.length > 0 ? (
                member?.map((d, i) => (
                  <tr key={d?.id}>
                    <td className='text-center'>{i + 1}</td>
                    <td>
                      <div className='user'>
                        <img className='imgSize' src={UserImg} alt='user-img' />
                        <div className='name'>
                          <span className='nameOne'>{d?.names}</span>
                          <span className='nameTwo'>{d?.age}</span>
                        </div>
                      </div>
                    </td>
                    <td className='text-center'>{d?.uhid}</td>
                    <td className='text-center'>
                      {d?.mobile ? <MaskedNumber mobile={d?.mobile} /> : '-'}
                    </td>
                    <td className='text-center'>{d?.date}</td>
                    <td className='text-center'>{d?.memberDate}</td>
                    <td className='text-center'>
                      {capitalizeFirstLetter(d?.relation_with_primary_partner)}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={7} className='text-center'>
                    {member?.length === 0
                      ? `${NoMemberinFHIR}`
                      : `${loadingText}`}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div>
          <div className='search-table'>
            <p className='search-para'>Benefits:</p>
          </div>
          {!isEmpty(sortedBenefits) ? (
            Object?.keys(sortedBenefits)?.map((category) => (
              <div key={category} className='category'>
                <table className='data-table admin-table table-fixed'>
                  <thead>
                    <tr>
                      <th className='width-minor'>{tableText?.slNo}</th>
                      <th>
                        {category === `${Categories}`
                          ? `${Categories}`
                          : `${Products} (${category})`}
                      </th>
                      <th className='text-center'>{tableText?.Allowecount}</th>
                      <th className='text-center'>{tableText?.Quantity}</th>
                      <th className='text-center'>
                        {tableText?.DiscountPrice}(%)
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {sortedBenefits?.[category]?.map(
                      (item: any, index: any) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{stringFunction(item?.innerKey)}</td>
                          <td className='text-center'>{item?.allowedCount}</td>
                          <td className='text-center'>{item?.quantity}</td>
                          <td className='text-center'>{item?.discountPrice}</td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            ))
          ) : (
            <table className='data-table admin-table table-fixed'>
              <thead>
                <tr>
                  <th className='width-minor'>{tableText?.slNo}</th>
                  <th>{Products}</th>
                  <th className='text-center'>{tableText?.Allowecount}</th>
                  <th className='text-center'>{tableText?.Quantity}</th>
                  <th className='text-center'>{tableText?.DiscountPrice}(%)</th>
                </tr>
              </thead>
              <tbody>
                <td colSpan={5} className='text-center'>
                  {loading ? `${loadingText}` : `${noBenefits}`}
                </td>
              </tbody>
            </table>
          )}
        </div>
        {invoices.length > 0 && (
          <div>
            <div className='search-table'>
              <p className='search-para'>{InvoiceList}</p>
            </div>
            <table className='data-table admin-table table-fixed'>
              <thead>
                <tr>
                  <th className='width-minor'>{tableText?.slNo}</th>
                  <th className='text-center'>{tableText?.uhid}</th>
                  <th className='text-center'>{tableText?.name}</th>
                  <th className='text-center'>{InvoiceLink}</th>
                </tr>
              </thead>
              <tbody>
                {invoices?.length > 0 ? (
                  invoices?.map((d, i) => (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td className='text-center'>{d?.uhid}</td>
                      <td className='text-center'>{d?.innerKey}</td>
                      <td
                        className='text-center pointer'
                        onClick={() => redirectPage(d?.invoices?.[0]?.id)}
                      >
                        {d?.invoices?.[0]?.name}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={4} className='text-center'>
                      {loadingText}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  )
}
export default ABMembershipDetails
