export type labTests = {
  description: string
  id: number
  name: string
  panelId: number
  panelName: string
  sampleName: string
  sampleId: number
}
export type Panels = {
  description: string
  id: number
  lab_tests: labTests[]
  name: string
  sampleId: number
  sampleName: string
}

export type labTestsType = {
  origin: string
  lab_tests: any
  patientUhid: any
  patientThopId: any
  patient_id: any
  requested_by: any
  ordered_by_name: any
  summary: string
  ordered_on: string
  lr_number: string
  status: string
  validation_completed_on: string
  collection_completed_on: string
  id: number
  source: string
  panels: Panels[]
  patientId: string
}
export type RadiologyTestsType = {
  origin: string
  patientUhid: string
  patientThopId: string
  patient_id: any
  requested_by: string
  ordered_by_name: string
  summary: string
  ordered_on: string
  lr_number: string
  status: string
  validation_completed_on: string
  id: number
  source: string
  radiology_order_tests: any[]
  radiology_test_results: any[]
  patientId: string
}

export type appointmentType = {
  id: number
  date: string
  desc: string
  resource: {
    statusHistory: any
    subject: {
      reference: string
    }
  }
}

export type upComingAppointmentType = {
  date: any
  docName: string
  service: string
  time: string
  status: any
  appointmentId: any
  origin: string
}

export type allAppointments = {
  date: any
  docName: string
}

export const visitSuccess = 'Visit Created Successfully'
export const mobile = 'mobile'
export const NewOPDObservations = 'NewOPDObservations'
export const triaged = 'triaged'
export const InProgress = 'InProgress'
export const FetchDataError = 'Error fetching data:'
export const unpaid = 'unpaid'
