export interface DeviceDetails {
  ambulanceIdentifier: string
  ambulanceNumber: string
  emtPhone: string
  driverPhone: string
  proDeviceId: string
  ambulanceStatus: string
  mapId: number | undefined | null
  totalCount: number | undefined | null
  id: number | undefined | null
}

export interface DeviceForm {
  ambulanceIdentifier: string
  ambulanceNumber: string
  emtPhone: string
  driverPhone: string
  proDeviceId: string
  ambulanceStatus: string
  mapId: number | undefined | null
}

export interface ProrithmDevices {
  sNo: number
  bedId: number | undefined | null
  id: number | undefined | null
  locationName: string | undefined | null
  prorithmDeviceId: string
  roomId: number | undefined | null
  type: string | undefined | null
  wardId: number | undefined | null
}

export interface Wards {
  id: number | undefined | null
  floor_number: string | undefined | null
  ward_name: string | undefined | null
}

export interface Rooms {
  id: number
  room_name: string | undefined | null
  room_number: string | undefined | null
  ward_name: string | undefined | null
  ward_id: number | undefined | null
}

export interface Beds {
  id: number | undefined | null
  bed_number: string | undefined | null
  status: string | undefined | null
  patient_id: string | undefined | null
  room_name: string | undefined | null
  room_id: number | undefined | null
}

export interface AmbulanceDetails {
  ambulanceNumber: string | undefined | null
  location: string | undefined | null
  driverPhone: string | undefined | null
  emtPhone: string | undefined | null
  status: string | undefined | null
}

export interface BedDetails {
  wardId: number | undefined | null
  wardName: string | undefined | null
  roomId: number | undefined | null
  roomName: string | undefined | null
  bedId: number | undefined | null
  bedName: string | undefined | null
}

export interface AmbulanceDialogDetails {
  prorithmDeviceId: string | null | undefined
  locationName: string | null | undefined
  ambulanceNumber: string | null | undefined
  driverPhoneNumber: string | null | undefined
  emtNumber: string | null | undefined
  ambulanceStatus: string | null | undefined
}

export const INTENT_CREATE_UPDATE_DEVICE = 'insertProrithmDetails'
export const INTENT_GET_DEVICES = 'getProRithmDeviceDetails'
export const INTENT_DELETE_DEVICE = 'deleteProrithmDetails'
export const INTENT_GET_WARD_ROOM_BED_DETAILS = 'getWardRoomBedDetails'
export const AMBULANCE_STATUS_OPTIONS = [
  {
    label: 'Active',
    value: 'ACTIVE',
  },
  {
    label: 'InActive',
    value: 'INACTIVE',
  },
]

export const endpoint = {
  addDevice: 'api/ipd/prorithm-bed-location-mapping/saveProrithmBedLocation',
  editProrithmMapping:
    'api/ipd/prorithm-bed-location-mapping/editProrithmBedLocation',
  getAllDevices: 'api/ipd/prorithm-bed-location-mapping/getAllDeviceMappings',
  checkDeviceMapping:
    'api/ipd/prorithm-bed-location-mapping/getDeviceMappingType?prorithmDeviceId=',
  deleteDevice:
    'api/ipd/prorithm-bed-location-mapping/deleteProrithmDevice?prorithmDeviceId=',
  ambDetails:
    'api/ipd/prorithm-bed-location-mapping/getDeviceById?prorithmDeviceId=',
}

export const mappingStatus = {
  notMapped: 'NOT MAPPED',
  bed: 'BED',
  bedStatus: 'Device already mapped to bed',
  location: 'LOCATION',
  ambulance: 'AMBULANCE',
  locationStatus: 'Device already mapped to location',
  ambulanceStatus: 'Device already mapped to ambulance',
}

export const constants = {
  mapDeviceLayout: 'mapDevice',
  ambulanceLayout: 'ambulance',
  addDevice: 'Add a device',
  ambulance: 'Ambulance',
  mapDevice: 'Map a device',
  close: 'Close',
  submit: 'Submit',
  location: 'location',
  available: 'AVAILABLE',
  selectLocation: 'Select Location',
  activeStatus: 'active',
  bedType: 'Bed Type',
  critical: 'Critical',
  nonCritical: 'Non-critical',
  selectWard: 'Select Ward',
  selectRoom: 'Select Room',
  selectBed: 'Select Bed',
  askDeleteDevice: 'Are you sure you want to delete device?',
  delete: 'Delete',
  cancel: 'Cancel',
  mapTo: 'Map to',
  bed: 'bed',
  ambulanceText: 'ambulance',
  no: 'No',
  yes: 'Yes',
  editMappedDevice: 'Edit mapped device',
  reMap: 'Re-map to:',
  ambulanceDetails: 'Ambulance Details',
  deviceId: 'Device Id',
  ambulanceNumber: 'Ambulance Number',
  driverPhone: 'Driver Phone',
  emtPhone: 'EMT Phone',
  status: 'Status',
  locationText: 'Location',
  enterValidDeviceId: 'Enter valid device id',
  deviceIdHelperText: 'Device Id format: AB:CD:EF:GH:IJ:KL',
  active: 'ACTIVE',
  inactive: 'INACTIVE',
}

export const messages = {
  deviceMappedSuccessfully: 'Device mapped successfully.',
  deviceDeletedSuccssfully: 'Device deleted successfully.',
  deviceAddedSuccessfully: 'Device added successfully.',
  failedToAddDevice: 'Failed. Invalid ID or Device might already exists.',
  mappingAlreadyExistsError:
    'Network error or Mapping to this device already exists',
  getAllDevicesError: 'An error occurred while getting all devices.',
  checkMappingStatusError: 'Failed to get device mapping status.',
  deleteDeviceFailed: 'Failed to delete device.',
  ambDetailsError: 'Failed to get ambulance details.',
  ambulanceNumberInvalid:
    'Ambulance number must be alphanumeric and cannot contain special characters',
  ambulanceNumberRequired: 'Ambulance number is required',
  locationRequired: 'Location is required',
  driverPhoneInvalid: 'Driver phone number must be exactly 10 digits',
  driverNumberRequired: 'Driver phone number is required',
  emtPhoneInvalid: 'EMT phone number must be exactly 10 digits',
  emtNumberRequired: 'EMT phone number is required',
  statusRequired: 'Status is required',
}

export const paginationOptions = [20, 40, 60, 80, 100]
