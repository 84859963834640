import React, { useRef, useEffect, useState } from 'react'
import { AdminTableProps, Package } from '../../models'
import { useAppSelector } from '../../../../app/hooks'
import { selectedLmsAdminSearch, selectedLmsStatus } from '../../lmsSlice'
import { getSearchResults } from '../../utils'
import LinkButton from './LinkButton'
import { LabTests } from '../../../labTests/models'
import { IconButton } from '@mui/material'
import DeleteOutlined from '@mui/icons-material/DeleteOutlined'
import { panelTableHeadings } from './constants'
import { create, menuValues, update } from '../../constants'
import ProvenanceView from '../../../../components/ProvenanceView'
import KeycloakService from '../../../../utils/keycloakService'
import { SUPER_ADMIN_DELETE } from '../../../../utils/roles'
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined'
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined'
import '../../../administration/admin.scss'
import { DeleteConfirmationDialog } from './DeleteConfirmationDialog'
import { Archive } from './Archive'
import { activeText } from '../../../Radiology/Constants/constants'

const PanelTable = ({ data, onEdit }: AdminTableProps) => {
  const searchParam = useAppSelector(selectedLmsAdminSearch)
  const status = useAppSelector(selectedLmsStatus)
  const [tableData, setTableData] = useState(data)
  const textLength = 30
  const [more, setMore] = useState(false)
  const [moreIndex, setMoreIndex] = useState(-1)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [selectedPanel, setSelectedPanel] = useState<Package | null>(null)

  useEffect(() => {
    if (Array?.isArray(data) && data?.length > 0) {
      setTableData(data)
    }
  }, [data])

  let filtered = getSearchResults(tableData, searchParam)
  filtered = filtered?.filter((d) => d?.status === status)

  filtered?.sort?.((a: any, b: any) => {
    if (a?.department?.department_name < b?.department?.department_name) {
      return -1
    } else if (
      a?.department?.department_name > b?.department?.department_name
    ) {
      return 1
    } else {
      return a?.name?.toLowerCase() < b?.name?.toLowerCase() ? -1 : 1
    }
  })

  const tableContainerRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    const resizeHandler = () => {
      const container = tableContainerRef.current
      if (container) {
        const availableHeight: any =
          window.innerHeight - container.getBoundingClientRect().top
        container.style.maxHeight = `${availableHeight - 20}px`
      }
    }

    window.addEventListener('resize', resizeHandler)
    resizeHandler()

    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])

  const toggleView = (more: boolean, index: number) => {
    setMore(!more)
    setMoreIndex(index === moreIndex ? -1 : index)
  }

  const renderInterpretation = (interpretation: any, index: number) => {
    const text = interpretation.slice(0, textLength)
    if (interpretation.length <= textLength || moreIndex === index) {
      return interpretation
    }

    return <>{text}</>
  }
  const [expandedRows, setExpandedRows] = useState<Set<number>>(new Set())
  const toggleRow = (rowIndex: number) => {
    setExpandedRows((prev) => {
      const newExpandedRows = new Set(prev)
      if (newExpandedRows?.has(rowIndex)) {
        newExpandedRows?.delete(rowIndex)
      } else {
        newExpandedRows?.add(rowIndex)
      }
      return newExpandedRows
    })
  }
  const renderLabTests = (labTests: LabTests[], rowIndex: number) => {
    const initialItemCount = 2
    const isExpanded = expandedRows?.has(rowIndex)

    return (
      <ul className='labTestParameter'>
        {labTests
          ?.slice(0, isExpanded ? labTests.length : initialItemCount)
          ?.map((item: LabTests) => (
            <li key={item?.id}>• {item?.name}</li>
          ))}
        {labTests?.length > initialItemCount && (
          <li>
            <span onClick={() => toggleRow(rowIndex)} className='linkbtns'>
              {isExpanded ? '...View Less' : '...View More'}
            </span>
          </li>
        )}
      </ul>
    )
  }

  const handleOpenDeleteDialog = (pkg: Package) => {
    setSelectedPanel(pkg)
    setDeleteDialogOpen(true)
  }
  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false)
    setSelectedPanel(null)
  }

  return (
    <div
      className='data-table table-fixed admin-table-table-container table-overflow-y'
      ref={tableContainerRef}
    >
      <table className='data-table table-fixed admin-table relative '>
        <thead className='sticky'>
          <tr>
            <th className='table-w-4'>{panelTableHeadings?.ThopId}</th>
            <th className='table-w-5'>{panelTableHeadings?.OdooId}</th>
            <th className='table-w-6'>{panelTableHeadings?.ShortForm}</th>
            <th className='table-w-10'>{panelTableHeadings?.TestName}</th>
            <th className='table-w-8'>{panelTableHeadings?.Department}</th>
            <th className='table-w-8'>{panelTableHeadings?.Sample}</th>
            <th className='table-w-10'> {panelTableHeadings?.ParameterName}</th>
            <th className='table-w-5'> {panelTableHeadings?.ParaCount}</th>
            <th className='table-w-8'>{panelTableHeadings?.HandledAt}</th>
            <th className='table-w-5'>{panelTableHeadings?.Price}</th>
            <th className='table-w-5'>{panelTableHeadings?.TAT}</th>
            <th className='table-w-7'>{panelTableHeadings?.Interpretation}</th>
            <th className='table-w-8'>{panelTableHeadings?.createdBy}</th>
            <th className='table-w-8'>{panelTableHeadings?.updatedBy}</th>
            <th className='table-w-6'>{panelTableHeadings?.Actions}</th>
          </tr>
        </thead>
        <tbody>
          {filtered?.length ? (
            filtered?.map((d: any, index: any) => {
              const testCount = d?.lab_tests?.length ?? 0
              return (
                <tr key={d?.id}>
                  <td>{d?.id}</td>
                  <td>{d?.odoo_test_id}</td>
                  <td>{d?.test_short_form}</td>
                  <td>
                    <LinkButton id={d?.id} name={d?.name} onClick={onEdit} />
                  </td>
                  <td>{d?.department?.department_name ?? '-'}</td>
                  <td>{d?.sample?.name}</td>
                  <td>{renderLabTests(d?.lab_tests || [], index)}</td>
                  <td>{testCount}</td>
                  <td>{d?.handled_at ?? '-'}</td>
                  <td>{d?.price ?? '-'}</td>
                  <td>{d?.tat ?? '1'}</td>
                  <td className='interpretation'>
                    {d?.interpretation ? (
                      <div>
                        {renderInterpretation(d?.interpretation, index)}
                        <span
                          className='linkbtns'
                          onClick={() => toggleView(more, index)}
                        >
                          {index === moreIndex
                            ? '...View Less'
                            : '...View More'}
                        </span>
                      </div>
                    ) : (
                      '-'
                    )}
                  </td>
                  <td className='text-center'>
                    <ProvenanceView
                      date={d?.created_on ?? ''}
                      name={d?.created_by ?? ''}
                      mode={create}
                    />
                  </td>
                  <td className='text-center'>
                    <ProvenanceView
                      date={d?.updated_on ?? ''}
                      name={d?.updated_by ?? ''}
                      mode={update}
                    />
                  </td>
                  <td>
                    <div className='set-action-icons'>
                      {d?.status === activeText ? (
                        <IconButton
                          title='Archive'
                          size='small'
                          onClick={() =>
                            Archive(d, setTableData, menuValues?.Panel)
                          }
                          disabled={
                            !KeycloakService?.hasRole([SUPER_ADMIN_DELETE])
                          }
                        >
                          <ArchiveOutlinedIcon fontSize='small' />
                        </IconButton>
                      ) : (
                        <IconButton
                          title='Un-Archive'
                          size='small'
                          onClick={() =>
                            Archive(d, setTableData, menuValues?.Panel)
                          }
                          disabled={
                            !KeycloakService?.hasRole([SUPER_ADMIN_DELETE])
                          }
                        >
                          <UnarchiveOutlinedIcon fontSize='small' />
                        </IconButton>
                      )}
                      <IconButton
                        title='Delete'
                        size='small'
                        onClick={() => handleOpenDeleteDialog(d)}
                        disabled={
                          !KeycloakService?.hasRole([SUPER_ADMIN_DELETE])
                        }
                      >
                        <DeleteOutlined fontSize='small' />
                      </IconButton>
                    </div>
                  </td>
                </tr>
              )
            })
          ) : (
            <tr>
              <td colSpan={5} className='text-center'>
                No Panels Found
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {deleteDialogOpen && (
        <DeleteConfirmationDialog
          openDialog={deleteDialogOpen}
          closeDialog={handleCloseDeleteDialog}
          data={selectedPanel}
          name={selectedPanel?.name}
          setData={setTableData}
          item={menuValues?.Panel}
        />
      )}
    </div>
  )
}

export default PanelTable
