import * as React from 'react'
import Box from '@mui/material/Box'
import {
  Button,
  ButtonGroup,
  Drawer,
  IconButton,
  useTheme,
} from '@mui/material'
import useCustomStyles from '../../utils/hooks/CustomStylesHook'
import CloseIcon from '@mui/icons-material/Close'
import {
  CarePlan,
  selectCarePlans,
  addCarePlan,
  removeCarePlanWithPatientId,
} from './CarePlanSlice'
import NotificationCard from './NotificationCard'
import CarePlanModal from './CarePlanModal'
import { useWebSocket } from './Socket'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import {
  MESSAGE_TYPES,
  NOTIFICATION_BUTTONS,
  NOTIFICATION_HISTORY_STATUS,
  ROUTE_IPD_NAVIGATE,
} from './Constants'
import NotificationSound from '../../assets/audio/NotificationSound.mp3'
import { useNavigate } from 'react-router-dom'
import { setQueryParam } from '../bedManagement/IpdVisit/IpdVisitSlice'
import { toast } from 'react-toastify'
import MarkChatUnreadOutlinedIcon from '@mui/icons-material/MarkChatUnreadOutlined'
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined'
import { getUnreadNotifications } from '../../services/NotificationServices'

const styles = (theme: any) => ({
  notificationContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'center',
    gap: 10,
    width: 400,
    height: '100%',
  },
  notificationHeadingContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    backgroundColor: '#4682b4',
    color: '#ffffff',
    height: '6%',
  },
  notificationHeading: {
    flexGrow: 1,
    textAlign: 'center',
    fontSize: '1.2rem',
    fontWeight: 600,
  },
  notificationCardContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    height: '95%',
    gap: 10,
    overflowY: 'auto',
    overflowX: 'hidden',
    boxSizing: 'border-box',
    scrollBehavior: 'smooth',
  },
  notificationCard: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: 0.5,
    width: '95%',
    borderRadius: 5,
    minHeight: '80px',
  },
  cardBtn: {
    textTransform: 'capitalize',
    padding: 'unset',
  },
  cardIcon: {
    color: '#183f7c',
    fontSize: '2.5rem',
  },
  dialogCloseIconBtn: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: theme.palette.grey[500],
  },
  customSnackbar: {
    '&.MuiPaper-root': {
      backgroundColor: 'white',
    },
    '&.MuiPaper-elevation': {
      border: '1px solid red',
    },
    '&.MuiPaper-elevation6': {
      boxShadow: 'none',
    },
    '&.MuiSnackbarContent-root': {
      padding: '20px',
    },
  },
  btnGroup: {
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.5)',
  },
  btnSelected: {
    color: '#ffffff !important',
    background: '#CC5500 !important',
    borderColor: '#CC5500 !important',
  },
  btnUnselected: {
    background: 'white',
    fontSize: '11px',
    textTransform: 'unset',
    width: '80px',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
})

const NotificationDrawer = {
  '& .MuiDrawer-paper': {
    height: '100%',
    overflow: 'auto',
    // backgroundColor: '#D3D3D3',
    background: 'rgba(255, 255, 255, 0.2)',
    backdropFilter: 'blur(10px)',
  },
}

const NotificationList = ({
  openNotificatons,
  toggleDrawer,
}: {
  openNotificatons: boolean
  toggleDrawer: (open: boolean) => () => void
}) => {
  const theme = useTheme()
  const classes = useCustomStyles(styles, theme)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const playSound = () => {
    const audio = new Audio(NotificationSound)
    audio.muted = true

    audio.addEventListener('canplaythrough', () => {
      audio
        .play()
        .then(() => {
          audio.muted = false
        })
        .catch((error) => {
          console.error('Audio playback failed:', error)
        })
    })
  }

  const { createSocketConnection, sendData, closeSocketConnection } =
    useWebSocket(
      process.env.REACT_APP_NOTIFICATION_SOCKET_URL,
      process.env.REACT_APP_NOTIFICATION_SOCKET_APP_TYPE,
      (message) => {
        console.log('Received WebSocket message:', message)
        if (
          message?.messageType === MESSAGE_TYPES?.CARE_PLAN &&
          message?.patientID
        ) {
          dispatch(addCarePlan(message))
          document.body.click()
          playSound()
          toast(
            <NotificationCard
              item={message}
              handleOpenClick={handleOpenClick}
              elevation={0}
            />
          )
        } else if (
          message?.messageType === MESSAGE_TYPES?.ACCEPT_CARE_PLAN ||
          message?.messageType === MESSAGE_TYPES?.REJECT_CARE_PLAN
        ) {
          dispatch(removeCarePlanWithPatientId(message?.patientID || ''))
        }
      }
    )
  const notifications = useAppSelector(selectCarePlans)
  const [openModal, setOpenModal] = React.useState(false)
  const [selectedNotification, setSelectedNotification] =
    React.useState<CarePlan>()

  const [selectedBtn, setSelectedBtn] = React.useState(
    NOTIFICATION_BUTTONS.Live
  )
  const [historyNotifications, setHistoryNotifications] =
    React.useState<CarePlan[]>()

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const handleOpenModal = () => {
    setOpenModal(true)
  }

  const handleOpenClick = (carePlan: any) => {
    setSelectedNotification(carePlan)
    handleOpenModal()
    dispatch(
      setQueryParam({ patientId: carePlan?.patientID, id: carePlan?.visitId })
    )
    navigate(ROUTE_IPD_NAVIGATE)
  }
  const getNotificationHistory = async () => {
    if (selectedBtn === NOTIFICATION_BUTTONS?.Unread) {
      const response = await getUnreadNotifications(NOTIFICATION_HISTORY_STATUS)
      if (response?.success) {
        setHistoryNotifications(response?.data)
      } else {
        console.error('Error:', response?.message)
      }
    }
  }

  React.useEffect(() => {
    if (selectedBtn === NOTIFICATION_BUTTONS?.Unread) {
      getNotificationHistory()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBtn])

  React.useEffect(() => {
    createSocketConnection()
    const intervalId = setInterval(() => {
      sendData({
        messageType: 'dummy-message',
        patientID: '',
        patientLocation: '',
        message: 'Ping Message',
      })
    }, 50000)

    return () => {
      closeSocketConnection()
      clearInterval(intervalId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {openModal && (
        <CarePlanModal
          openModal={openModal}
          handleCloseModal={handleCloseModal}
          selectedNotification={selectedNotification}
          sendData={sendData}
          setSelectedBtn={setSelectedBtn}
        />
      )}
      <Drawer
        anchor='right'
        open={openNotificatons}
        onClose={toggleDrawer(false)}
        slotProps={{
          backdrop: {
            invisible: true,
          },
        }}
        disableScrollLock
        sx={NotificationDrawer} // class not applying
      >
        <Box role='presentation' className={classes?.notificationContainer}>
          <Box className={classes?.notificationHeadingContainer}>
            <Box className={classes?.notificationHeading}>Notifications</Box>
            <IconButton onClick={toggleDrawer(false)}>
              <CloseIcon
                fontSize='small'
                htmlColor='#ffffff'
                id='close-notification-drawer'
              />
            </IconButton>
          </Box>
          <ButtonGroup
            variant='outlined'
            aria-label='Basic button group'
            size='small'
            className={classes?.btnGroup}
          >
            <Button
              className={`${classes?.btnUnselected} ${
                selectedBtn === NOTIFICATION_BUTTONS?.Live
                  ? classes?.btnSelected
                  : ''
              }`}
              onClick={() => setSelectedBtn(NOTIFICATION_BUTTONS?.Live)}
              endIcon={<MarkChatUnreadOutlinedIcon fontSize='small' />}
            >
              Live
            </Button>
            <Button
              className={`${classes?.btnUnselected} ${
                selectedBtn === NOTIFICATION_BUTTONS?.Unread
                  ? classes?.btnSelected
                  : ''
              }`}
              onClick={() => setSelectedBtn(NOTIFICATION_BUTTONS?.Unread)}
              endIcon={<HistoryOutlinedIcon fontSize='small' />}
            >
              Unread
            </Button>
          </ButtonGroup>
          {selectedBtn === NOTIFICATION_BUTTONS?.Live ? (
            <Box
              className={classes?.notificationCardContainer}
              pl={1.2}
              pr={1.2}
            >
              {notifications
                ?.slice()
                ?.reverse()
                ?.map((item, index: number) => {
                  return (
                    <NotificationCard
                      item={item}
                      index={index}
                      key={index}
                      handleOpenClick={handleOpenClick}
                      elevation={3}
                    />
                  )
                })}
            </Box>
          ) : (
            <Box
              className={classes?.notificationCardContainer}
              pl={1.2}
              pr={1.2}
            >
              {historyNotifications
                ?.slice()
                ?.reverse()
                .map((item, index: number) => {
                  return (
                    <NotificationCard
                      item={item}
                      index={index}
                      key={index}
                      handleOpenClick={handleOpenClick}
                      elevation={3}
                    />
                  )
                })}
            </Box>
          )}
        </Box>
      </Drawer>
    </>
  )
}

export default NotificationList
