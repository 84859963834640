import { useEffect, useState } from 'react'
import { startSxpProxy } from '../../../utils/api'
import { IPD_PROJECT_ID } from '../../../utils/constants'
import { useNavigate } from 'react-router-dom'
import { emptyString } from '../../Radiology/Constants/constants'
import { allocateApi, allocation } from '../modals'
import {
  WardDetail,
  bedFilter,
  bedNavigations,
  transferType,
  updateBedType,
} from '../constants'
import KeycloakService from '../../../utils/keycloakService'
import { toast } from 'react-toastify'

type Props = {
  bed?: bedFilter
  onClose: (image: boolean) => void
  dischargedBedData: updateBedType
  setRefresh: any
  tableRefresh: any
  mode: string
}

const AddTransferDialog = ({
  bed,
  dischargedBedData,
  onClose,
  tableRefresh,
  mode,
}: Props) => {
  const navigate = useNavigate()
  const [transferData, setTransferData] = useState<transferType>({
    wardId: '',
    bedId: '',
    transferReason: '',
  })
  const [wardDetails, setWardDetails] = useState<WardDetail[]>([])
  const [bedDetails, setBedDetails] = useState<bedFilter[]>([])

  const handleInputChange = (event: any) => {
    const { name, value } = event.target
    setTransferData((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  useEffect(() => {
    startSxpProxy(IPD_PROJECT_ID, allocateApi.getWardsByLocation, {
      locationId: bed?.locationId,
    }).then((data) => {
      const wards = data.data.ward_details.map((e: WardDetail) => {
        const id = e.id
        const name = e?.ward_name
        return {
          id,
          name,
        }
      })
      setWardDetails(wards)
    })
  }, [bed?.locationId])
  useEffect(() => {
    const state = {
      wardId: Number(transferData.wardId),
      locationId: bed?.locationId,
    }
    startSxpProxy(
      IPD_PROJECT_ID,
      allocateApi.getBedDetailsByLocationAndWards,
      state
    ).then((data) => {
      const result: bedFilter[] = data.data.ward_details.flatMap(
        (ward: any) => {
          return ward.room_details.flatMap((room: any) => {
            return room.beds.map((bed: any) => {
              const status = bed.bed_patient_assignment_maps?.find(
                (status: any) => status?.bed_status === allocation.assigned
              )
              return {
                bed_id: bed.id,
                bed_number: bed.bed_number,
                patient_name: bed.patient_name,
                admission_date: bed.admission_date ?? emptyString,
                discharged_date: bed.discharged_date ?? emptyString,
                bed_category:
                  bed.bed_category_id === 3
                    ? allocation.critical
                    : allocation.nonCritical,
                status: bed.status,
                room_id: bed.room_id,
                patient_id: bed.patient_id,
                room_name: room.room_name,
                room_number: room.room_number,
                location_id: room.location_id,
                ward_name: ward.ward_name,
                floor_number: ward.floor_number,
                bed_patient_map_id: status?.bed_id,
                bed_patient_id: status?.id,
                bed_patient_assigned_id: status?.patient_id,
                bed_patient_status: status?.bed_status,
                bed_patient_discharge_date: status?.date_of_discharge,
              }
            })
          })
        }
      )
      if (result.length === 0) {
        setBedDetails([])
      }
      const bedsAvailable = result.filter(
        (e: bedFilter) => e.status === allocation.available
      )
      setBedDetails(bedsAvailable)
    })
  }, [transferData.wardId, bed?.locationId])

  const handleClick = () => {
    const updateBedDetails = [
      {
        where: { id: { _eq: parseInt(bed?.id) } },
        _set: {
          patient_id: null,
          patient_name: null,
          status: allocation?.available,
          admission_date: null,
          date_changed: new Date().toISOString(),
        },
      },
      {
        where: { id: { _eq: parseInt(transferData.bedId) } },
        _set: {
          patient_id: dischargedBedData?.patientId,
          patient_name: dischargedBedData?.patientName,
          status: allocation?.occupied,
          date_created: new Date().toISOString(),
          admission_date: new Date().toISOString(),
        },
      },
    ]
    const state = {
      id: parseInt(bed?.id),
      patientId: emptyString,
      bedMapId: dischargedBedData?.bedMapId,
      status: allocation.available,
      patientName: emptyString,
      admissionDate: emptyString,
      dischargedDate: emptyString,
      patientMapId: dischargedBedData?.patientId,
      newBedId: parseInt(transferData.bedId),
      bedStatus: allocation.assigned,
      voidedReason: transferData.transferReason,
      transferStatus: allocation.transferred,
      updatedBy: KeycloakService.getUsername(),
      dateOfDischarge: new Date().toISOString(),
      bedDetails: updateBedDetails,
    }
    startSxpProxy(IPD_PROJECT_ID, allocateApi?.createTransferBedDetails, state)
      .then(async (data) => {
        const bedNumber =
          data?.data?.data?.insert_bed_patient_transfer_map_one?.bed?.bed_number
        toast?.success(`Transferred to ${bedNumber}`)
        if (mode === allocation.list) {
          await tableRefresh()
          onClose(false)
          navigate(bedNavigations?.bedIpdOverview)
        }
        if (mode === allocation.layout) {
          onClose(false)
          if (window.location.pathname === bedNavigations?.bedIpd) {
            navigate(bedNavigations?.bedIpdOverview)
          } else {
            navigate(bedNavigations?.bedIpd)
          }
        }
      })
      .catch((err) => {
        err?.response?.data?.error?.errors?.forEach((message: string) => {
          toast.error(message)
        })
        toast?.error(err?.response?.data?.message)
      })
  }
  return (
    <>
      <div>
        <span>{allocation.proposed}</span>
      </div>
      <table className='bed-table relative'>
        <thead>
          <tr>
            <th className='text-left w-40'>{allocation.selectWard}</th>
            <th className='text-left'>{allocation.selectBed}</th>
            <th className='text-center'>{allocation.reasonTransfer}</th>
            <th className='text-center'>{allocation.conformation}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className='text-left w-40'>
              <select
                className=''
                name='wardId'
                value={transferData.wardId}
                onChange={handleInputChange}
              >
                <option value={emptyString}>{allocation.selectWard}</option>
                {wardDetails.map((op: any) => (
                  <option key={op.id} value={op.id}>
                    {op.name}
                  </option>
                ))}
              </select>
            </td>
            <td>
              {bedDetails.length === 0 ? (
                <span>{allocation.noAvailableBeds}</span>
              ) : (
                <select
                  name='bedId'
                  value={transferData.bedId}
                  onChange={handleInputChange}
                >
                  <option value={emptyString}>{allocation.selectBed}</option>
                  {bedDetails.map((op: any) => (
                    <option key={op.bed_id} value={op.bed_id}>
                      {op.bed_number}
                    </option>
                  ))}
                </select>
              )}
            </td>
            <td>
              <input
                type='text'
                name='transferReason'
                className='input-reason-container'
                value={transferData.transferReason}
                onChange={handleInputChange}
              />
            </td>
            <td>
              <input
                type='button'
                value='Transfer'
                disabled={
                  !(
                    transferData.transferReason !== emptyString &&
                    transferData.bedId !== emptyString &&
                    transferData.wardId !== emptyString
                  )
                }
                onClick={handleClick}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </>
  )
}
export default AddTransferDialog
