import React, { useEffect, useState } from 'react'
import './radiologyModel.scss'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import { useTheme } from '@mui/material'
import useCustomStyles from '../../../../utils/hooks/CustomStylesHook'
import { radiologyColor } from '../../Utils/RadiologyColors'
const styles = () => ({
  filterIcon: {
    fontSize: '19px',
    color: radiologyColor?.semiTransparentWhite,
    background: radiologyColor?.transparent,
  },
  customHeader: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  filterIconContainer: {
    position: 'relative',
    display: 'inline-block',
    top: '3px',
  },
  withRedDot: {
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      width: '8px',
      height: '8px',
      backgroundColor: radiologyColor?.red,
      borderRadius: '50%',
      border: `2px solid ${radiologyColor?.white}`,
      transform: 'translate(50%, -50%)',
    },
  },
})

const CustomHeader = (props: any) => {
  const theme = useTheme()
  const classes = useCustomStyles(styles, theme)
  const [isFiltered, setIsFiltered] = useState(false)

  useEffect(() => {
    const onFilterChanged = () => {
      const filterModel = props?.api?.getFilterModel()
      setIsFiltered(!!filterModel[props?.column?.colId])
    }

    props?.api?.addEventListener('filterChanged', onFilterChanged)

    return () => {
      props?.api?.removeEventListener('filterChanged', onFilterChanged)
    }
  }, [props?.api, props?.column?.colId])

  return (
    <div className={classes?.customHeader}>
      <span>{props?.displayName}</span>
      <div
        onClick={(e) => props?.showColumnMenu(e?.target)}
        className={`${classes?.filterIconContainer} ${
          isFiltered && classes?.withRedDot
        }`}
      >
        <FilterAltOutlinedIcon className={classes?.filterIcon} />
      </div>
    </div>
  )
}

export default CustomHeader
