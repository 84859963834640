import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { startSxpProxy } from '../../../utils/api'
import {
  AB_MEMBERHSIP_PROJECT_ID,
  CHAT_PROJECT_ID,
} from '../../../utils/constants'
import ToastMessage from '../../lms/components/ToastMessage'
import { toastOptions } from '../../lms/models'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppSelector } from '../../../app/hooks'
import { selectSelectedLocationId } from '../../location/locationSlice'
import { getAgeInYears } from '../../../utils/dateUtils'
import Loading from '../../../form/fields/Loading'
import {
  ABMemberPatient,
  MemberPatient,
  membershipTableHeadings,
} from '../../membership/models'
import {
  ABMembershipIntent,
  changeRegType,
  editmembermsg,
  MembershipList,
  membershipmember,
  noMemberFound,
  Remove,
  Save,
} from '../constants'
import { tableText } from '../../administration/constants'
import KeycloakService from '../../../utils/keycloakService'
import { ABMemebrship } from '../../bedManagement/endpoints/EndPoints'

type member = {
  members: ABMemberPatient[]
  onMemberUnSelect: (val: string) => void
  previousMembers: MemberPatient[]
}

const EditABMemberTable = ({
  members,
  onMemberUnSelect,
  previousMembers,
}: member) => {
  const [loading, setLoading] = useState(false)
  const { id } = useParams()
  const navigate = useNavigate()
  const [primaryIndex, setPrimaryIndex] = useState<any>(previousMembers)

  const location = useAppSelector(selectSelectedLocationId)
  useEffect(() => {
    setPrimaryIndex(previousMembers?.[0]?.id)
  }, [previousMembers])

  const handleCheckboxChange = (id: string) => {
    if (id !== primaryIndex) {
      startSxpProxy(CHAT_PROJECT_ID, ABMembershipIntent?.checkRegAndRestrict, {
        patientIdentifier: members?.find((e) => e?.id === id)?.uhid,
      })
        ?.then(() => {
          setPrimaryIndex(id)
        })
        ?.catch((err) => {
          toast(
            <ToastMessage
              message={err?.response?.data?.error ?? { changeRegType }}
            />,
            {
              ...toastOptions,
              type: 'error',
            }
          )
        })
    } else {
      setPrimaryIndex(-1)
    }
  }

  const handleUnselect = (id: string) => {
    if (id == primaryIndex) {
      setPrimaryIndex(-1)
    }
    onMemberUnSelect(id)
  }
  const filteredArray = previousMembers.filter(
    (a) => !members?.some((b) => a?.uhid === b?.uhid)
  )
  const edit = async () => {
    setLoading(true)
    try {
      const primary = members?.find((e) => e?.id === primaryIndex)?.uhid
      const associated = members?.filter((d) => d?.id !== primaryIndex)
      const associatedPartnerRefs = associated?.map((d) => ({
        ref: d?.uhid,
        relation: d?.relation || d?.relation_with_primary_partner,
      }))
      const state = {
        location,
        thop_last_updated_user_name: KeycloakService?.getFullname(),
        body: {
          partner: primary,
          line_id: id,
          associated_partner_refs: associatedPartnerRefs,
        },
      }

      await startSxpProxy(
        AB_MEMBERHSIP_PROJECT_ID,
        ABMembershipIntent?.editMembership,
        state
      )

      if (filteredArray?.length > 0) {
        for (let i = 0; i < filteredArray?.length; i++) {
          try {
            await startSxpProxy(
              CHAT_PROJECT_ID,
              ABMembershipIntent?.revertMembership,
              {
                patientIdentifier: filteredArray?.[i]?.uhid,
                patientId: filteredArray?.[i]?.id,
              }
            )
          } catch (err) {
            console?.log(err)
          }
        }
      }
      toast(<ToastMessage message={editmembermsg} />, {
        ...toastOptions,
        type: 'success',
      })
      setTimeout(() => {
        navigate(ABMemebrship)
      }, 2000)
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error?.data?.message || membershipmember
      toast(<ToastMessage message={errorMessage} />, {
        ...toastOptions,
        type: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className='search-table'>
      {loading && <Loading />}
      <p className='admin-header'>{MembershipList}</p>
      <div className='tabFixHead'>
        <table className='data-table table-fixed admin-table'>
          <thead>
            <tr>
              <th className='table-w-5'>{membershipTableHeadings?.slno}</th>
              <th className='text-left table-w-15'>{tableText?.MUHID}</th>
              <th className='text-left table-w-30'>{tableText?.name}</th>
              <th className='text-center table-w-6'>{tableText?.gender}</th>
              <th className='text-center table-w-8'>{tableText?.age}</th>
              <th className='text-center table-w-6'>{tableText?.action}</th>
            </tr>
          </thead>
          <tbody>
            {members?.length > 0 ? (
              members?.map((d: MemberPatient) => (
                <tr className='memberId' key={d?.id}>
                  <td>
                    <input
                      type='checkbox'
                      checked={d?.id === primaryIndex}
                      onChange={() => handleCheckboxChange(d?.id)}
                      disabled={d?.id === primaryIndex}
                    />
                  </td>
                  <td>{d?.uhid}</td>
                  <td>
                    <button className='lms-btn'>{d?.name}</button>
                  </td>
                  <td className='text-center'>{d?.gender}</td>
                  <td className='text-center'>{getAgeInYears(d?.dob)}</td>
                  <td className='text-center'>
                    <button
                      className={
                        d?.id === primaryIndex
                          ? 'disabled-button'
                          : 'typeButton'
                      }
                      disabled={d?.id === primaryIndex}
                      onClick={() => handleUnselect(d?.id)}
                    >
                      {Remove}
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6} className='text-center'>
                  {noMemberFound}
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <div className='flexDivsOne'>
          <button className={'btn btn-primaryBtn'} onClick={edit}>
            {Save}
          </button>
        </div>
      </div>
    </div>
  )
}
export default EditABMemberTable
