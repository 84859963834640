export const PATIENTS_READ = 'patients-read'
export const PATIENTS_WRITE = 'patients-write'
export const PATIENT_DASHBOARD_READ = 'patient-dashboard-read'
export const PATIENT_DASHBOARD_WRITE = 'patient-dashboard-write'
export const APPOINTMENTS_READ = 'appointments-read'
export const APPOINTMENTS_WRITE = 'appointments-write'
export const MEETING_LINK = 'meeting-link'
export const CREATE_VISIT = 'create-visit'
export const SXP_CHAT = 'sxp-chat'
export const HISTORY_READ = 'history-read'
export const HISTORY_WRITE = 'history-write'
export const VITALS_READ = 'vitals-read'
export const VITALS_WRITE = 'vitals-write'
export const SYSTEMIC_READ = 'systemic-read'
export const SYSTEMIC_WRITE = 'systemic-write'
export const DIAGNOSIS_READ = 'diagnosis-read'
export const DIAGNOSIS_WRITE = 'diagnosis-write'
export const MEDICATIONS_READ = 'medications-read'
export const MEDICATIONS_WRITE = 'medications-write'
export const LAB_ORDERS_READ = 'lab-orders-read'
export const LAB_ORDERS_WRITE = 'lab-orders-write'
export const SLOTS_READ = 'slots-read'
export const SLOTS_CREATE = 'slots-create'
export const SLOTS_DELETE = 'slots-delete'
export const CALENDAR_READ = 'calendar-read'
export const LMS_READ = 'lms-read'
export const COLLECTION_WRITE = 'collection-write'
export const TESTING_WRITE = 'testing-write'
export const VALIDATION_WRITE = 'validation-write'
export const LMS_ADMIN_WRITE = 'lms-admin-write'
export const PACKAGES_WRITE = 'packages-write'
export const SEQUENCE_WRITE = 'sequence-write'
export const LOCATION_SWITCH = 'location-switch'
export const MEMBERSHIP_READ = 'membership-read'
export const MEMBERSHIP_WRITE = 'membership-write'
export const MEMBERSHIP_UPDATE = 'membership-update'
export const ADMINISTRATION_READ = 'admin-read'
export const ADMINISTRATION_WRITE = 'admin-write'
export const REPORTING_READ = 'reporting-read'
export const REPORTING_WRITE = 'reporting-write'
export const RADIOLOGY_READ = 'radiology-read'
export const RADIOLOGY_WRITE = 'radiology-write'
export const RADIOLOGY_TESTING_WRITE = 'radiology-testing-write'
export const RADIOLOGY_VALIDATION_WRITE = 'radiology-validation-write'
export const IPD_READ = 'ipd-read'
export const IPD_WRITE = 'ipd-write'
export const IPD_ADMIN = 'ipd-admin'
export const SUPER_ADMIN_DELETE = 'super-admin-deletion'
export const RADIOLOGY_ORDERS_WRITE = 'radiology-orders-write'
export const IPD_DISCHARGE_ADMIN = 'ipd-discharge-admin'
