import { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { OrderEvent, sampleRow } from '../../../labTests/models'
import { startSxpProxy } from '../../..//../utils/api'
import {
  CHAT_PROJECT_ID,
  OPD_PROJECT_ID,
  RADIOLOGY_PROJECT_ID,
} from '../../../../utils/constants'
import { toast } from 'react-toastify'
import ToastMessage from '../../../lms/components/ToastMessage'
import { toastOptions } from '../../../lms/models'
import {
  TotalPackagePrice,
  getPatientIdentifierById,
  saveOrder,
  transformData,
} from '../../Utils/utils'
import KeycloakService from '../../../../utils/keycloakService'
import { RADIOLOGY_ORDERS_WRITE } from '../../../../utils/roles'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import {
  selectLocationById,
  selectSelectedLocationId,
} from '../../../location/locationSlice'
import Select from 'react-select'
import { Doctor } from '../../../scheduler/schedulerSlice'
import {
  fetchSlotsDoctorsAsync,
  selectSlotsDoctors,
} from '../../../slots/slotsSlice'
import { setQueryParam } from '../../../membership/membershipSlice'
import { Button, CircularProgress, IconButton, useTheme } from '@mui/material'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import { useCategories } from '../RadiologyModel/useCategories'
import {
  setRadiologyDateFilter,
  setRadiologyActiveTab,
  setRadiologyShowBack,
  selectedRadiologyPatientCheck,
} from '../../RadiologySlice'
import DoneIcon from '@mui/icons-material/Done'
import {
  RadiologyLabTest,
  RadiologyOrders,
  RadiologyPackage,
  selectedPackage,
  selectedTest,
  RadiologyRow,
} from '../../models'
import { readableDateTimeFormat } from '../../../../utils/dateUtils'
import { useRadiologyOrganizations } from '../../useRadiologyOrganizations'
import {
  dialogMessages,
  emptyString,
  error,
  modeOptions,
  opdObj,
  originModes,
  originOptions,
  packageLabel,
  patientIdentifier,
  patientText,
  radiologyActions,
  radiologyButtons,
  radiologyIntents,
  radiologyModes,
  radiologyTestStatus,
  sampleName,
  selectPackage,
  selectTests,
  success,
  tabs,
  testLabel,
  testName,
  visit,
} from '../../Constants/constants'
import { Patient } from 'fhir/r4'
import { radiologyEndPoint } from '../../RadiologyEndPoints'
import CustomDialog from '../../dialogComponent/CustomDialogComponent'
import TestReplacementDialogContent from '../../dialogComponent/TestReplacementDialogContent'
import MatchedTestsDialogContent from '../../dialogComponent/MatchedTestsDialogContent'
import useCustomStyles from '../../../../utils/hooks/CustomStylesHook'
import { radiologyColor } from '../../Utils/RadiologyColors'
const styles = () => ({
  ordersContainer: {},
  orderButtons: {
    display: 'flex',
    gap: '8px',
    marginBottom: '12px',
  },
  orderBtn: {
    backgroundColor: radiologyColor?.white,
    color: radiologyColor?.black,
    fontSize: '13px',
    fontWeight: 500,
    padding: '4px 8px',
    cursor: 'pointer',
    border: `1px solid ${radiologyColor?.prussianBlue}`,
    borderRadius: '4px',
    '&:disabled': {
      opacity: 0.6,
      cursor: 'default',
    },
  },
  activeOrderBtn: {
    backgroundColor: radiologyColor?.prussianBlue,
    color: radiologyColor?.pureWhite,
    border: `1px solid ${radiologyColor?.prussianBlue}`,
  },
  radiologyOrderTable: {
    height: '70vh',
  },
  orderSubmitButton: {
    textAlign: 'end',
    marginBottom: '3px',
  },
  pointerEventsNone: {
    pointerEvents: 'none',
  },
  pointerEventsAuto: {
    pointerEvents: 'auto',
  },
  dataTable: {
    width: '100%',
    borderRadius: '8px',
    border: `1px solid ${radiologyColor?.softGrey}`,
    borderCollapse: 'collapse',
    '& th, & td': {
      padding: '6px',
      fontSize: '12px',
      border: `1px solid ${radiologyColor?.softGrey}`,
    },
    '& th': {
      fontWeight: 500,
    },
    '& tbody tr:nth-of-type(even)': {
      backgroundColor: radiologyColor?.whisperGray,
    },
  },
  tableFixed: {
    tableLayout: 'fixed',
  },
  textCenter: {
    textAlign: 'center',
  },
  headAction: {
    backgroundColor: radiologyColor?.steelBlue,
    position: 'sticky',
    top: '0px',
    color: radiologyColor?.white,
  },
  tableHeadMode: {
    width: '10%',
  },
  tableHeadRequested: {
    width: '20%',
  },
  tableHeadSample: {
    width: '20%',
  },
  tableHeadTests: {
    width: '22%',
  },
  tableHeadParameters: {
    width: '22%',
  },
  tableHeadActions: {
    width: '8%',
  },
  requestedBy: {
    alignSelf: 'center',
    padding: '5px 8px',
    marginLeft: '20px',
  },
  selectTest: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  disabledAction: {
    color: radiologyColor?.green,
  },
  saveAction: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  circularProgressAction: {
    display: 'flex',
  },
})

const RadiologyOrder = ({
  patient,
  modeType,
  originMode,
}: {
  patient?: Patient
  modeType?: string
  originMode?: string
}) => {
  const theme = useTheme()
  const classes = useCustomStyles(styles, theme)
  const { id }: any = useParams()
  const [loading, setLoading] = useState(true)
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false)
  const [uhid, setUhid] = useState<string>('')
  const [typeMode, setTypeMode] = useState<string>('')
  const [newrowadded, setNewRowAdded] = useState(false)
  const [patientId, setPatientId] = useState<string>('')
  const [order, setOrder] = useState<RadiologyOrders>({})
  const [activeIndex, setActiveIndex] = useState(-1)
  const [orders, setOrders] = useState<RadiologyOrders[]>([])
  const [origin, setOrigin] = useState<string>('OPD')
  const locationId = useAppSelector(selectSelectedLocationId)
  const patientCheck = useAppSelector(selectedRadiologyPatientCheck)
  const location = useAppSelector((state) =>
    selectLocationById(locationId, state)
  )
  const [selectedTests, setSelectedTests] = useState<selectedTest[]>([])
  const [rows, setRows] = useState<RadiologyRow[]>([])
  const [ordersEvent, setOrdersEvent] = useState<OrderEvent[]>([])
  const [testAdded, setTestAdded] = useState<boolean>(false)
  const [lrNumber, setLrNumber] = useState('')
  const navigate = useNavigate()
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [filteredTestsToReplace, setFilteredTestsToReplace] = useState<
    RadiologyRow[]
  >([])
  const [filteredTests, setFilteredTests] = useState<RadiologyRow[]>([])
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false)
  const [matchedTests, setMatchedTests] = useState<RadiologyLabTest[]>([])
  const [pendingPackage, setPendingPackage] = useState(null)
  const [pendingPackageTests, setPendingPackageTests] =
    useState<RadiologyPackage | null>(null)
  const [testNameOptions, setTestNameOptions] = useState<selectedTest[]>([])
  const [packageTests, setPackageTests] = useState<RadiologyPackage[]>([])
  const organizations = useRadiologyOrganizations()
  const doctors = useAppSelector(selectSlotsDoctors)
  const VisitTableRef = useRef<HTMLDivElement | null>(null)
  const [newRow, setNewRow] = useState<RadiologyRow>({
    mode: null,
    requestedBy: null,
    sampleName: null,
    testName: null,
    origin: null,
    packageName: null,
    packageTests: null,
  })
  const dispatch = useAppDispatch()
  const locations = useLocation()
  useEffect(() => {
    if (locations?.pathname?.startsWith(radiologyEndPoint?.radiologyCreate)) {
      setNewRow((prevState) => ({
        ...prevState,
        origin: opdObj,
      }))
    }
  }, [locations?.pathname, setNewRow])
  const getAllRadiologyOrders = () => {
    setLoading(true)
    const intent = radiologyIntents?.getRadiologyOrdersByVisitId
    startSxpProxy(RADIOLOGY_PROJECT_ID, intent, { visitId: id })
      .then((data) => {
        const orders: RadiologyOrders[] = data?.data?.radiology_orders
        setOrders(orders)
        const state = { encounterId: id }
        startSxpProxy(OPD_PROJECT_ID, radiologyIntents?.getVisitApi, state)
          .then((data) => {
            const id = data?.data?.subject?.reference?.split('/')?.[1]
            setPatientId(data?.data?.subject?.reference?.split('/')?.[1])
            startSxpProxy(OPD_PROJECT_ID, radiologyIntents?.getPatientApi, {
              id,
            }).then((data) => {
              const uhid = data?.data?.identifier?.find(
                (e: any) => e?.sysytem === patientIdentifier
              )?.value
              setUhid(uhid)
            })
          })
          .catch(() => {
            if (modeType === visit && originMode === originModes?.ipd) {
              navigate(radiologyEndPoint?.bedAssignmentList)
            }
            if (modeType === visit && originMode === originModes?.opd) {
              navigate(radiologyEndPoint?.opd)
            }

            toast.error(
              <ToastMessage message={dialogMessages?.ipdRunTimeException} />
            )
          })
          .finally(() => setLoading(false))
      })
      .catch((err) => console.error(err))
      .finally(() => setLoading(false))
  }

  const handleModeChange = (selectedOption: any) => {
    setNewRow((prev) => ({ ...prev, mode: selectedOption }))
  }
  const handleSampleNameChange = (selectedOption: any) => {
    setNewRow((prev) => ({
      ...prev,
      sampleName: selectedOption,
      testName: null,
    }))
    if (!selectedOption?.value) return
    startSxpProxy(
      RADIOLOGY_PROJECT_ID,
      radiologyIntents?.getAllRadiologyTestsBySampleId,
      {
        radiologySampleId: parseInt(selectedOption?.value),
      }
    )
      .then((response) => {
        const testNames =
          response?.data?.radiology_sample?.[0]?.radiology_lab_tests?.map(
            (test: any) => ({
              label: test?.name,
              value: test?.id,
              sampleId: test?.sample_id,
              sampleName: test?.radiology_sample?.name,
            })
          ) || []
        const selectedTestLabels = rows?.map((row) => row?.testName?.label)
        const filteredTestNames = testNames?.filter(
          (test: any) => !selectedTestLabels.includes(test?.label)
        )
        setTestNameOptions(filteredTestNames)
      })
      .catch((error) => {
        console.error('Error fetching test names:', error)
        setTestNameOptions([])
      })
  }
  const handlePackageChange = (selectedOption: any) => {
    const selectedPackageTests: RadiologyPackage | undefined =
      packageTests?.find((e: any) => e?.packageId === selectedOption?.value)
    const selectedTests = selectedPackageTests?.radiology_lab_tests
    const PackageRowsAvailable = rows
      ?.filter((e: RadiologyRow) => e?.packageTests)
      ?.map((e: RadiologyRow) =>
        e?.packageTests?.radiology_lab_tests?.map(
          (e: RadiologyLabTest) => e?.testId
        )
      )
    const flattenedTestsArray = [...new Set(PackageRowsAvailable?.flat())]
    const flattenedTestIntArray = flattenedTestsArray?.map(Number)
    const matchedTestsArray = selectedTests?.filter((test: RadiologyLabTest) =>
      flattenedTestIntArray.includes(Number(test?.testId))
    )
    if (matchedTestsArray && matchedTestsArray?.length > 0) {
      setPendingPackage(selectedOption)
      if (selectedPackageTests) {
        setPendingPackageTests(selectedPackageTests)
      }
      setMatchedTests(matchedTestsArray)
      setOpenConfirmDialog(true)
    } else {
      if (selectedPackageTests) {
        setNewRowLogic(selectedOption, selectedPackageTests)
      }
    }
  }

  const setNewRowLogic = (
    selectedOption: any,
    selectedPackageTests: RadiologyPackage
  ) => {
    setNewRow((prev: any) => ({
      ...prev,
      packageName: selectedOption,
      packageTests: selectedPackageTests,
      sampleName: null,
      testName: null,
    }))
    const selectedTestLabels = rows?.map((row) => row?.packageTests)
    const filteredTestNames = packageTests?.filter(
      (test: any) => !selectedTestLabels.includes(test?.packageId)
    )
    setPackageTests(filteredTestNames)
    const tests = packageTests?.find(
      (e: any) => e?.packageId === selectedOption?.value
    )
    const selectedTestIds = tests?.radiology_lab_tests?.map(
      (e: any) => e?.testId
    )
    if (selectedTestIds) {
      const filteredTestName = testNameOptions?.filter(
        (test: any) => !selectedTestIds.includes(test?.value)
      )
      setTestNameOptions(filteredTestName)
    }
  }
  const handleConfirmPackageReplace = () => {
    setOpenConfirmDialog(false)
    pendingPackageTests && setNewRowLogic(pendingPackage, pendingPackageTests)
  }
  const generateGlobalId = () => {
    startSxpProxy(CHAT_PROJECT_ID, radiologyIntents?.generateGlobalId, {}).then(
      (data) => {
        setLrNumber(data?.data?.id)
      }
    )
  }

  const handleCancelPacakgeReplace = () => {
    setOpenConfirmDialog(false) // Close the dialog and do nothing
  }
  const getAllRadiologyTests = () => {
    startSxpProxy(
      RADIOLOGY_PROJECT_ID,
      radiologyIntents?.getAllRadiologyPackages,
      {}
    ).then((data) => {
      const mapped: RadiologyPackage[] = data?.data?.radiology_packages?.map(
        (test: any) => {
          return {
            packageId: test?.id,
            packageName: test?.name,
            price: test?.price,
            testCount: test?.radiology_lab_tests?.length ?? 0,
            radiology_lab_tests: test?.radiology_lab_tests,
          }
        }
      )
      setPackageTests(mapped)
      if (
        window.location.pathname ===
        `${radiologyEndPoint?.radiologyCreate}/${id}`
      ) {
        fetchData(mapped)
      }
      if (modeType === visit) {
        getAllRadiologyOrders()
      }
    })
  }

  const executeFunction = () => {
    const state = {}
    startSxpProxy(
      RADIOLOGY_PROJECT_ID,
      radiologyIntents?.getRadiologyTestsAPI,
      state
    )
      .then((data) => {
        const panelData = data?.data?.radiology_lab_test ?? []
        const tests = panelData?.map((test: any) => ({
          label: test?.name,
          value: test?.id,
          sampleId: test?.sample_id,
          sampleName: test?.radiology_sample?.name,
        }))
        const selectedTestLabels = rows?.map((row) => row?.testName?.label)
        const filteredTests = tests?.filter(
          (test: any) => !selectedTestLabels.includes(test?.label)
        )
        setTestNameOptions(filteredTests)
      })
      .catch((error) => {
        console.error('Error fetching test names:', error)
        setTestNameOptions([])
      })
  }
  const handleTestNameChange = (selectedOption: any) => {
    setNewRow((prev) => ({
      ...prev,
      testName: { label: selectedOption?.label, value: selectedOption?.value },
      sampleName: {
        label: selectedOption?.sampleName,
        value: selectedOption?.sampleId,
      },
      packageName: null,
      packageTests: null,
    }))
  }

  const handleSave = async () => {
    if (!KeycloakService?.hasRole([RADIOLOGY_ORDERS_WRITE])) return
    if (!rows?.length) {
      toast(<ToastMessage message={dialogMessages?.addOneTest} />, {
        ...toastOptions,
        type: error,
      })
      return
    }
    setSubmitButtonLoading(true)
    const getTestPanel = (row: RadiologyRow) => ({
      sampleName: row?.sampleName?.label,
      sampleId: row?.sampleName?.value,
      testName: row?.testName?.label,
      testId: row?.testName?.value,
      isTestSelect: true,
      packageId: null,
    })
    const handlePackageTests = (row: RadiologyRow, result: any[]) => {
      row?.packageTests?.radiology_lab_tests?.forEach(
        (test: RadiologyLabTest) => {
          const existingTest = result?.find(
            (item) => Number(item?.testId) === Number(test?.testId)
          )
          if (existingTest) {
            existingTest.packageId?.push(row?.packageTests?.packageId)
          } else {
            result.push({
              testId: Number(test?.testId),
              sampleId: test?.sampleId,
              testName: test?.testName,
              sampleName: test?.sampleName,
              isTestSelect: false,
              packageId: [row?.packageTests?.packageId],
            })
          }
        }
      )
    }
    const filteredPanels = rows
      ?.filter((row) => row?.mode?.value === radiologyModes?.test)
      ?.map(getTestPanel)
    const result: any[] = []
    rows
      ?.filter((row) => row?.mode?.value === radiologyModes?.package)
      ?.forEach((row) => handlePackageTests(row, result))

    const packageIds = [
      ...new Set(result?.flatMap((item) => item?.packageId)?.filter(Boolean)),
    ]

    const combinedResult = [...filteredPanels, ...result]
    const mode: 'visit' | 'patient' = modeType === visit ? visit : patientText
    const locationName = location?.resource?.name || emptyString
    const requestedBy = rows?.[rows.length - 1]?.requestedBy?.value
    const price = TotalPackagePrice(rows) ?? 0

    saveOrder(
      mode,
      order,
      id,
      patientId,
      uhid,
      lrNumber,
      combinedResult,
      [],
      locationName,
      rows?.[0]?.packageTests?.packageId ?? 0,
      price,
      requestedBy,
      origin,
      packageIds,
      combinedResult
    )
      .then((data) => {
        setOrder(data?.data?.insert_radiology_orders_one)
        toast(
          <ToastMessage
            message={
              order?.id
                ? dialogMessages?.modifedRadiologyOrder
                : dialogMessages?.createRadiologyOrder
            }
          />,
          {
            ...toastOptions,
            type: success,
          }
        )
        setSubmitButtonLoading(false)
        if (modeType === visit) {
          setActiveIndex(-1)
          setRows([])
          getAllRadiologyOrders()
        }
        if (modeType !== visit || originMode === originModes?.opd) {
          dispatch(
            setRadiologyDateFilter(new Date()?.toISOString()?.slice(0, 10))
          )
          dispatch(setRadiologyActiveTab(tabs?.testing))
          navigate(radiologyEndPoint?.radiologyDashboard)
        }
      })
      .catch((err) => {
        setSubmitButtonLoading(false)
        console.log(err, 'err')
        setRows([])
      })
  }

  const handleActiveOrderChage = (
    orders: RadiologyOrders[],
    mapped: RadiologyPackage[]
  ) => {
    let activeOrder: any = {}
    if (
      window.location.pathname.startsWith(radiologyEndPoint?.radiologyCreate)
    ) {
      activeOrder = orders?.[orders?.length - 1]?.radiology_order_tests?.every(
        (test: any) =>
          test?.test_status === radiologyTestStatus?.orderModified ||
          test?.test_status === radiologyTestStatus?.ordered ||
          test?.test_status === radiologyTestStatus?.reOrdered ||
          test?.test_status === radiologyTestStatus?.deleted
      )
        ? orders?.[orders?.length - 1] ?? {}
        : orders?.[-1] ?? {}
    } else {
      activeOrder = orders[activeIndex] ?? {}
    }

    setOrder(activeOrder)
    const formattedRows: RadiologyRow[] = activeOrder
      ? transformData(activeOrder, mapped)
      : []
    setRows(formattedRows || [])
    setOrdersEvent(activeOrder?.order_events ?? [])
    setOrder(activeOrder)
    if (
      activeOrder?.id &&
      activeOrder?.radiology_order_tests &&
      activeOrder?.radiology_order_tests.length >= 1
    ) {
      setNewRow({
        mode: {
          label: activeOrder?.package_ids ? packageLabel : testLabel,
          value: activeOrder?.package_ids
            ? radiologyModes?.package
            : radiologyModes?.test,
        },
        requestedBy: null,
        sampleName: null,
        testName: null,
        origin: null,
        packageName: null,
        packageTests: null,
      })
    } else {
      setNewRow({
        mode: null,
        requestedBy: null,
        sampleName: null,
        testName: null,
        origin: null,
        packageName: null,
        packageTests: null,
      })
    }
  }
  const fetchData = async (mapped: RadiologyPackage[]) => {
    setLoading(true)
    try {
      const radiologyOrderData = await startSxpProxy(
        RADIOLOGY_PROJECT_ID,
        radiologyIntents?.getRadiologyOrdersForPatientForOpd,
        { patientId: id }
      )
      const orders: RadiologyOrders[] =
        radiologyOrderData?.data?.radiology_orders ?? []
      handleActiveOrderChage(orders, mapped)
    } catch (err) {
      setLoading(false)
      console.error(err)
    } finally {
      setLoading(false)
    }
  }
  const handleSaveRow = () => {
    switch (true) {
      case newRow?.mode?.value === radiologyModes?.test && !newRow.sampleName:
        toast.warn(dialogMessages?.selectSample)
        break

      case newRow?.mode?.value === radiologyModes?.test && !newRow.testName:
        toast.warn(dialogMessages?.selectTest)
        break

      case newRow?.mode?.value === radiologyModes?.package &&
        !newRow.packageName:
        toast.warn(dialogMessages?.selectPackage)
        break

      case locations.pathname.startsWith(radiologyEndPoint?.radiologyCreate) &&
        !newRow.requestedBy:
        toast.warn(dialogMessages?.selectRequestedBy)
        break

      case !newRow.mode:
        toast.warn(dialogMessages?.selectMode)
        break

      default:
        if (
          rows?.length > 0 &&
          newRow?.mode?.value === radiologyModes?.package
        ) {
          const selectedPackageTest = newRow?.packageTests?.radiology_lab_tests
            ?.map((e: any) => e?.testId)
            .map((n: any) => Number(n))

          const filteredRows = rows?.filter((e: any) => e?.testName)
          const filtered = filteredRows?.filter(
            (obj: any) => !selectedPackageTest?.includes(obj?.testName?.value)
          )
          setFilteredTestsToReplace(filtered)

          const unFiltered = filteredRows?.filter(
            (obj: any) =>
              selectedPackageTest?.includes(obj?.testName?.value) ?? false
          )
          setFilteredTests(unFiltered)

          if (unFiltered?.length > 0) {
            setOpenDialog(true)
          } else {
            addRowToTable(filtered)
          }
        } else {
          addRowToTable([])
        }
        break
    }
  }
  const addRowToTable = (filtered: any[]) => {
    if (rows?.length > 0 && newRow?.mode?.value === radiologyModes?.package) {
      const filteredPackageTests = rows?.filter((e: any) => e?.packageTests)
      if (filteredPackageTests?.length > 0) {
        setRows([...filtered, ...filteredPackageTests, newRow])
      } else {
        setRows([...filtered, newRow])
      }
    } else {
      setRows((prevRows) => [...prevRows, newRow])
    }

    const nextRow = {
      mode: newRow?.mode,
      requestedBy: newRow?.requestedBy,
      origin: newRow?.origin,
      sampleName: null,
      testName: null,
      packageTests: null,
      packageName: null,
    }
    setNewRow(nextRow)
    setNewRowAdded(true)

    if (newRow?.testName) {
      setSelectedTests([...selectedTests, newRow?.testName])
    }

    setTestAdded((prev) => !prev)
  }
  const handleConfirmReplace = () => {
    setOpenDialog(false)
    addRowToTable(filteredTestsToReplace)
  }

  const handleRequestedByChange = (selectedOption: any) => {
    setNewRow((prev) => ({ ...prev, requestedBy: selectedOption }))
  }

  const handleOriginChange = (selectedOption: any) => {
    const selectedValue = selectedOption?.value || emptyString
    setOrigin(selectedValue)
    setNewRow((prev) => ({ ...prev, origin: selectedOption }))
  }
  const samples = useCategories()
  const sampleOptions: sampleRow[] = samples?.map((sample) => ({
    label: sample?.name,
    value: sample?.id,
  }))
  const packageOptions: { label: string; value: number }[] = packageTests?.map(
    (e: RadiologyPackage) => ({
      label: e?.packageName,
      value: e?.packageId,
    })
  )
  const handleDeleteRow = (index: number) => {
    setRows((prevRows: RadiologyRow[]) => {
      const updatedRows = [...prevRows]
      updatedRows.splice(index, 1)
      return updatedRows
    })
    setNewRowAdded(true)
  }
  const isPointerEventsDisabled = ordersEvent?.some((event) =>
    event.tests?.some(
      (test) =>
        test.status !== radiologyTestStatus?.orderModified &&
        test.status !== radiologyTestStatus?.ordered
    )
  )
  const renderParameters = (parameters: any) => {
    return (
      <div>
        {parameters?.map((item: any) => {
          return <li key={item?.testId}>{item?.testName}</li>
        })}
      </div>
    )
  }
  const handleCloseDialog = () => {
    setOpenDialog(false)
  }
  const handleCancelReplace = () => {
    setOpenDialog(false) // Close the dialog, do nothing
  }
  const checkOrderStatus = (order: RadiologyOrders) => {
    return order?.radiology_order_tests?.every(
      (test: any) =>
        test?.test_status === radiologyTestStatus?.orderModified ||
        test?.test_status === radiologyTestStatus?.ordered ||
        test?.test_status === radiologyTestStatus?.reOrdered ||
        test?.test_status === radiologyTestStatus?.deleted
    )
  }
  useEffect(() => {
    dispatch(fetchSlotsDoctorsAsync())
  }, [dispatch])
  useEffect(() => {
    const resizeHandler = () => {
      const container = VisitTableRef.current
      if (container) {
        const availableHeight =
          window.innerHeight - container.getBoundingClientRect().top
        container.style.maxHeight = `${availableHeight - 90}px`
      }
    }
    window.addEventListener('resize', resizeHandler)
    resizeHandler()
    return () => {
      window.removeEventListener('resize', resizeHandler)
      dispatch(setQueryParam({ q: '', param: '' }))
    }
  }, [dispatch])

  useEffect(() => {
    executeFunction()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testAdded])
  useEffect(() => {
    if (packageTests?.length > 0 && modeType === visit) {
      handleActiveOrderChage(orders, packageTests)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex])
  useEffect(() => {
    if (!order?.id) {
      generateGlobalId()
    }
  }, [order])
  useEffect(() => {
    const radiologyAction =
      !patientCheck || (patientCheck && rows?.length === 0)
        ? radiologyActions?.create
        : radiologyActions?.delete
    dispatch(setRadiologyShowBack(radiologyAction))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows, patientCheck])

  useEffect(() => {
    if (patient) {
      setUhid(getPatientIdentifierById(patient))
    }
    if (modeType) {
      setTypeMode(modeType)
      if (modeType === visit && originMode === originModes?.ipd) {
        setOrigin(originMode)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient, modeType])
  useEffect(() => {
    getAllRadiologyTests()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
    <>
      <CustomDialog
        open={openDialog}
        title={dialogMessages?.testListToBeRemoved}
        content={
          <TestReplacementDialogContent
            dialogMessages={dialogMessages}
            filteredTests={filteredTests}
          />
        }
        onClose={handleCloseDialog}
        onConfirm={handleConfirmReplace}
        onCancel={handleCancelReplace}
        primaryButtonText={radiologyButtons?.proceed}
        secondaryButtonText={radiologyButtons?.cancel}
      />
      <CustomDialog
        open={openConfirmDialog}
        title='Matched Tests Found'
        content={
          <MatchedTestsDialogContent
            dialogMessages={dialogMessages}
            matchedTests={matchedTests}
          />
        }
        onClose={handleCancelReplace}
        onConfirm={handleConfirmPackageReplace}
        onCancel={handleCancelPacakgeReplace}
        primaryButtonText={radiologyButtons?.yes}
        secondaryButtonText={radiologyButtons?.no}
      />
      <div>
        {modeType === visit && (
          <div className={classes?.orderButtons}>
            {orders?.map((ord, idx) => (
              <button
                className={`${classes?.orderBtn} ${
                  activeIndex === idx ? classes?.activeOrderBtn : ''
                }`}
                onClick={() => setActiveIndex(idx)}
                key={ord?.id}
                title={`Order ${ord?.id} - ${readableDateTimeFormat(
                  new Date(ord?.ordered_on ?? emptyString)
                )}`}
              >
                Order {ord?.id}
              </button>
            ))}
            <button
              className={`${classes?.orderBtn} ${
                activeIndex === -1 && classes?.activeOrderBtn
              }`}
              onClick={() => setActiveIndex(-1)}
            >
              New Order
            </button>
          </div>
        )}
        <div className={classes?.radiologyOrderTable}>
          <div className={classes?.orderSubmitButton}>
            <Button
              onClick={handleSave}
              disabled={
                !newrowadded ||
                (modeType === visit &&
                  activeIndex !== -1 &&
                  !checkOrderStatus(order)) ||
                rows?.length === 0 ||
                submitButtonLoading
              }
              size='small'
              variant='contained'
            >
              Submit
            </Button>
          </div>
          {loading ? (
            <div className={classes?.saveAction}>
              <CircularProgress className={classes?.circularProgressAction} />
              <span>{dialogMessages?.checkPreviousTests}</span>
            </div>
          ) : (
            <div
              className={`${
                isPointerEventsDisabled
                  ? classes?.pointerEventsNone
                  : classes?.pointerEventsAuto
              }`}
            >
              <table className={classes?.dataTable}>
                <thead className={classes?.headAction}>
                  <tr>
                    <th className={classes?.tableHeadMode}>Mode</th>
                    <th className={classes?.tableHeadRequested}>
                      Requested By
                    </th>
                    {typeMode !== visit && (
                      <th className={classes?.tableHeadRequested}>Origin</th>
                    )}
                    <th className={classes?.tableHeadSample}>
                      {newRow?.mode?.value === radiologyModes?.package
                        ? selectPackage
                        : sampleName}
                    </th>
                    <th className={classes?.tableHeadTests}>
                      {newRow?.mode?.value === radiologyModes?.package
                        ? selectTests
                        : testName}
                    </th>
                    <th className={classes?.tableHeadActions}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Select
                        value={newRow?.mode || ''}
                        onChange={handleModeChange}
                        options={modeOptions}
                      />
                    </td>
                    <td>
                      <Select
                        placeholder='Select Requested By'
                        className={classes?.requestedBy}
                        isClearable
                        value={newRow?.requestedBy || ''}
                        onChange={handleRequestedByChange}
                        options={[
                          { value: 'Self', label: 'Self' },
                          ...(organizations?.map((org) => ({
                            value: org?.name,
                            label: org?.name,
                          })) || []),

                          ...(doctors?.map((d: Doctor) => {
                            const doctorName = `Dr. ${d?.name}`
                            const specialty = d?.specialty
                              ? ` [${d?.specialty}]`
                              : emptyString
                            const displayName =
                              `${doctorName}${specialty}`?.trim()

                            return {
                              value: displayName,
                              label: displayName,
                            }
                          }) || []),
                        ]}
                      />
                    </td>
                    {typeMode !== visit && (
                      <td>
                        <Select
                          className='origin-select'
                          isClearable
                          value={newRow?.origin}
                          onChange={handleOriginChange}
                          options={originOptions}
                        />
                      </td>
                    )}
                    <td>
                      {newRow?.mode?.value === radiologyModes?.package && (
                        <Select
                          options={packageOptions?.map(
                            (option: selectedPackage) => ({
                              ...option,
                              isDisabled: rows?.some(
                                (r) =>
                                  r?.packageName &&
                                  r?.packageName?.value === option?.value
                              ),
                            })
                          )}
                          value={newRow.packageName}
                          onChange={handlePackageChange}
                          placeholder='Select Package'
                        />
                      )}
                      {newRow?.mode?.value === radiologyModes?.test && (
                        <Select
                          options={sampleOptions?.map((option) => ({
                            ...option,
                          }))}
                          value={newRow?.sampleName}
                          onChange={handleSampleNameChange}
                          placeholder='Select Sample Name'
                        />
                      )}
                    </td>
                    <td>
                      {newRow?.mode?.value === radiologyModes?.test && (
                        <Select
                          options={testNameOptions?.map((option) => {
                            const isTestDisabled = rows?.some(
                              (row) =>
                                (row?.testName &&
                                  row?.testName?.value === option?.value) ||
                                row?.packageTests?.radiology_lab_tests?.some(
                                  (test: any) =>
                                    Number(test?.testId) === option?.value
                                )
                            )

                            return {
                              ...option,
                              isDisabled: isTestDisabled,
                            }
                          })}
                          value={newRow?.testName}
                          onChange={handleTestNameChange}
                          placeholder='Select Test Name'
                          formatOptionLabel={(option: any) => (
                            <div className={classes?.selectTest}>
                              <span>{option?.label}</span>
                              <span>
                                {option?.isDisabled && (
                                  <DoneIcon
                                    className={classes?.disabledAction}
                                  />
                                )}
                              </span>
                            </div>
                          )}
                          isOptionDisabled={(option: any) => option?.isDisabled}
                        />
                      )}
                      {newRow?.mode?.value === radiologyModes?.package &&
                        renderParameters(
                          newRow?.packageTests?.radiology_lab_tests
                        )}
                    </td>
                    <td>
                      <button
                        onClick={handleSaveRow}
                        disabled={
                          modeType === visit &&
                          activeIndex !== -1 &&
                          !checkOrderStatus(order)
                        }
                      >
                        Add
                      </button>
                    </td>
                  </tr>
                  {rows?.map((row, index) => (
                    <tr key={index}>
                      <td>{row?.mode?.label}</td>
                      <td>{row?.requestedBy?.label}</td>
                      {locations.pathname.startsWith(
                        radiologyEndPoint?.radiologyCreate
                      ) && <td>{row?.origin?.label}</td>}
                      <td>
                        {row?.mode?.value === radiologyModes?.package
                          ? row?.packageName?.label
                          : row?.sampleName?.label}
                      </td>
                      <td>
                        {row?.mode?.value === radiologyModes?.package
                          ? renderParameters(
                              row?.packageTests?.radiology_lab_tests
                            )
                          : row?.testName?.label}
                      </td>
                      <td>
                        <IconButton
                          size='small'
                          onClick={() => handleDeleteRow(index)}
                          disabled={
                            modeType === visit &&
                            activeIndex !== -1 &&
                            !checkOrderStatus(order)
                          }
                        >
                          <DeleteOutlinedIcon fontSize='small' />
                        </IconButton>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default RadiologyOrder
