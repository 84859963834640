export const INTENSIVIST_COMMAND_CENTER_STATUS_OPTIONS = [
  {
    label: 'Pending',
    value: 'arrived',
  },
  {
    label: 'Accepted',
    value: 'finished',
  },
  {
    label: 'Rejected',
    value: 'cancelled',
  },
]

export const VISIT_STATUS_OPTIONS = [
  {
    label: 'InProgress',
    value: 'triaged',
  },
  {
    label: 'Completed',
    value: 'finished',
  },
  {
    label: 'Cancelled',
    value: 'cancelled',
  },
]

export const INTENSIVIST_COMMAND_CENTER = 'Intensivist Command Centre'
export const paramsId = 'id'
export const paramsBedId = 'bedId'
export const paramsBedStatus = 'bedStatus'
export const ipdRegId = 'regId'
