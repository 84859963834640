import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'

export type ProrithmSlice = {
  addDeviceCount: number
}

const initialState: ProrithmSlice = {
  addDeviceCount: 0,
}

const prorithmSlice = createSlice({
  name: 'prorithmDevices',
  initialState,
  reducers: {
    setAddDeviceCount: (state) => {
      state.addDeviceCount += 1
    },
  },
})

export const { setAddDeviceCount } = prorithmSlice.actions

export const selectAddDeviceCount = (state: RootState) =>
  state.prorithmDevices.addDeviceCount

export default prorithmSlice.reducer
