import { useRef, useEffect, useState } from 'react'
import CreateAppointment from '../../../assets/icons/cal.svg'
import Edit from '../../../assets/icons/edit_icon.svg'
import { FormattedPatient } from '../Patients'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import {
  ProvenanceData,
  selectPatientsCurrentPage,
  selectPatientsStatus,
  setRedirectTo,
} from '../patientsSlice'
import {
  sendSxpMessage,
  startSxpProxy,
  updateSxpState,
} from '../../../utils/api'
import {
  ADMIN_PROJECT_ID,
  CHAT_PROJECT_ID,
  REGISTRATION_PROJECT_ID,
} from '../../../utils/constants'
import KeycloakService from '../../../utils/keycloakService'
import { NavLink, useNavigate } from 'react-router-dom'
import MaskedNumber from '../../../components/MaskedNumber'
import {
  PATIENT_DASHBOARD_READ,
  PATIENT_DASHBOARD_WRITE,
  PATIENTS_WRITE,
  SXP_CHAT,
} from '../../../utils/roles'
import { Practitioner, Provenance } from 'fhir/r4'
import ProvenanceView from '../../../components/ProvenanceView'
import { getProvenanceData } from '../utils'
import { selectPageSize } from '../../../app/appSlice'
import { odooPharmacyLink } from '../../appointments/models'
import { selectSelectedLocationId } from '../../location/locationSlice'
import LocationByPrefix from '../dashboard/LocationByPrefix'

type Props = {
  patients: FormattedPatient[]
}

const RegistrationType = ({ regType }: { regType: string }) => {
  let text = 'Other'
  let title = 'Invalid Registration Type'

  switch (regType) {
    case 'fr':
      text = 'Full'
      title = 'Full Registration'
      break
    case 'qr':
      text = 'Quick'
      title = 'Quick Registration'
      break
    case 'er':
      text = 'ER'
      title = 'Emergency'
      break
    case 'pmc':
      text = 'Pharmacy'
      title = 'Pharmacy'
      break
    case 'lab':
      text = 'Lab'
      title = 'Laboratory'
      break
    case 'dgs':
      text = 'Diagnostics'
      title = 'Diagnostics'
      break
    case 'camp':
      text = 'Campaign'
      title = 'Campaign'
      break
    case 'membership':
    case 'membershipPrimary':
      text = 'MemberShip'
      title = 'MemberShip'
      break
    case 'ABMembershipQuick':
    case 'ABMembershipFull':
      text = 'AB MemberShip'
      title = 'AB MemberShip Full'
      break
    case 'package':
      text = 'Package'
      title = 'Package'
      break
  }

  return <span title={title}>{text}</span>
}

const PatientRow = ({
  d,
  provenances,
  index,
  onCreate,
  onEdit,
}: {
  d: FormattedPatient
  provenances: Record<string, ProvenanceData>
  index: number
  onCreate: () => void
  onEdit: () => void
}) => {
  const locationId = useAppSelector(selectSelectedLocationId)
  const openOdooInvoice = (id: string) => {
    startSxpProxy(ADMIN_PROJECT_ID, odooPharmacyLink, {
      invoiceId: id,
      location: locationId,
    })
      .then((data) => {
        const url = data?.data?.auth_link
        window.open(url, '_blank', 'noopener,noreferrer')
      })
      .catch((err) => console.error(err, 'err'))
  }
  const navigate = useNavigate()
  const handleNameClick = () => {
    navigate('/opd')
  }

  return (
    <tr>
      <td className='text-center'>{index + 1}</td>
      <td>{d?.id || d?.fhirId}</td>
      <td style={{ fontSize: '10px' }}>
        <RegistrationType regType={d?.registrationType} />
      </td>
      <td>
        {KeycloakService.hasRole([
          PATIENT_DASHBOARD_READ,
          PATIENT_DASHBOARD_WRITE,
        ]) ? (
          <NavLink
            to='/opd'
            state={{ fhirId: d?.fhirId, MUHId: d?.id }}
            onClick={handleNameClick}
          >
            {d?.name}
          </NavLink>
        ) : (
          d?.name
        )}
      </td>
      <td>
        <MaskedNumber mobile={d?.mobile} />
      </td>
      <td className='text-center'>{d?.gender}</td>
      <td className='text-center' title={d?.age}>
        {d?.age}
      </td>
      <td className='text-center'>
        <LocationByPrefix id={d?.id} />
      </td>
      <td className='text-center'>
        <ProvenanceView
          date={d?.registeredOn}
          name={provenances?.[d?.fhirId]?.createdBy}
          mode={'CREATE'}
        />
      </td>
      <td className='text-center'>
        <ProvenanceView
          date={provenances?.[d?.fhirId]?.updatedOn ?? d?.registeredOn}
          name={
            provenances?.[d?.fhirId]?.updatedBy ??
            provenances?.[d?.fhirId]?.createdBy
          }
          mode={'UPDATE'}
        />
      </td>
      <td className='text-center appointmentBtn'>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            onClick={KeycloakService.hasRole([SXP_CHAT]) ? onCreate : undefined}
            className={`custom-btn custom-btn-primary ${
              KeycloakService.hasRole([SXP_CHAT]) ? '' : 'auth-disabled'
            }`}
            title='Create Appointment'
            style={{
              cursor: KeycloakService.hasRole([SXP_CHAT])
                ? 'pointer'
                : 'default',
              padding: '8px',
            }}
          >
            <img
              alt='Create Appointment'
              src={CreateAppointment}
              style={{ height: '18px', width: '20px' }}
            />
          </div>

          <div
            onClick={
              KeycloakService.hasRole([PATIENTS_WRITE]) ? onEdit : undefined
            }
            title='Edit Patient'
            style={{
              cursor: KeycloakService.hasRole([PATIENTS_WRITE])
                ? 'pointer'
                : 'default',
              padding: '8px',
            }}
          >
            <img
              alt='Edit Patient'
              src={Edit}
              style={{ height: '18px', width: '20px' }}
            />
          </div>
        </div>
      </td>
      <td>
        {d.invoiceId && d.invoiceId !== 'unpaid' ? (
          <span
            style={{
              padding: '2px 4px',
              backgroundColor: 'teal',
              color: 'white',
              borderRadius: 2,
              marginLeft: 6,
              fontSize: 11,
              cursor: 'pointer',
            }}
            onClick={() => openOdooInvoice(d.invoiceId)}
          >
            Unpaid
          </span>
        ) : (
          '-'
        )}
      </td>
    </tr>
  )
}

const PatientTable = ({ patients }: Props) => {
  const status = useAppSelector(selectPatientsStatus)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const ids = patients.map((p) => p.fhirId).join(',')
  const [provenances, setProvenances] = useState<
    Record<string, ProvenanceData>
  >({})
  const currentPage = useAppSelector(selectPatientsCurrentPage)
  const pageSize = useAppSelector(selectPageSize)
  const calculateIndex = (currentIndex: number): number => {
    return (currentPage - 1) * pageSize + currentIndex
  }
  useEffect(() => {
    if (ids) {
      startSxpProxy(REGISTRATION_PROJECT_ID, 'fetchProvenance', {
        patientIds: ids,
      })
        .then((data) => {
          const entries =
            data.data?.entry?.map(
              (dde: { resource: Provenance | Practitioner }) => dde.resource
            ) ?? []
          const obj = getProvenanceData(entries)
          setProvenances(obj)
        })
        .catch((err) => console.log(err))
    }
  }, [ids])

  const createAppointment = async (d: FormattedPatient) => {
    if (KeycloakService.hasRole([SXP_CHAT])) {
      await sendSxpMessage(CHAT_PROJECT_ID, '::restart')
      const state = {
        patientId: d.fhirId,
        identifier: d.id,
      }
      await updateSxpState(CHAT_PROJECT_ID, state)
      window.chatSendMsg('bookAppointmentFromUI', KeycloakService.getUsername())
    }
  }

  const editPatient = (d: FormattedPatient) => {
    if (KeycloakService.hasRole([PATIENTS_WRITE])) {
      dispatch(setRedirectTo('patients'))
      navigate(`/patient-registration/${d.fhirId}`)
    }
  }

  const patientTableContainerRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    const resizeHandler = () => {
      const container = patientTableContainerRef.current
      if (container) {
        const availableHeight: any =
          window.innerHeight - container.getBoundingClientRect().top
        container.style.maxHeight = `${availableHeight - 60}px`
      }
    }

    window.addEventListener('resize', resizeHandler)
    resizeHandler()

    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])

  return (
    <div className='fixTableHead' ref={patientTableContainerRef}>
      {' '}
      <table className='data-table table-fixed admin-table'>
        <thead style={{ position: 'sticky', top: '0px', zIndex: 9 }}>
          <tr>
            <th className='table-w-3' style={{ padding: '6px' }}>
              Sl&nbsp;No
            </th>
            <th className='table-w-9'>UHID</th>
            <th className='table-w-10'>Reg.Type</th>
            <th className='text-left table-w-15'>Name</th>
            <th className='table-w-8'>Mobile No.</th>
            <th className='text-center table-w-6'>Gender</th>
            <th className='text-center table-w-6'>Age</th>
            <th className='text-center table-w-10'>Operating Unit</th>
            <th className='text-center table-w-10'>Created</th>
            <th className='text-center table-w-10'>Updated</th>
            <th className='text-center'>Action</th>
            <th className='text-center'>Pay.Status</th>
          </tr>
        </thead>
        <tbody>
          {patients?.length > 0 ? (
            patients?.map((d, i) => (
              <PatientRow
                key={d?.fhirId}
                d={d}
                provenances={provenances}
                index={calculateIndex(i)}
                onCreate={() => createAppointment(d)}
                onEdit={() => editPatient(d)}
              />
            ))
          ) : (
            <tr>
              <td colSpan={10} style={{ textAlign: 'center' }}>
                {status === 'loading'
                  ? 'Loading Patient Data...'
                  : 'No patients found'}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

export default PatientTable
