import React, { useRef, useEffect, useState } from 'react'
import { useAppSelector } from '../../../../app/hooks'
import { selectedLmsAdminSearch, selectedLmsStatus } from '../../lmsSlice'
import { getSearchResults } from '../../utils'
import { AdminTableProps, Package } from '../../models'
import LinkButton from './LinkButton'
import { LabTests } from '../../../labTests/models'
import ProvenanceView from '../../../../components/ProvenanceView'
import { IconButton } from '@mui/material'
import DeleteOutlined from '@mui/icons-material/DeleteOutlined'
import { create, update } from '../../../administration/constants'
import { PackageTableHeading } from './constants'
import { menuValues } from '../../constants'
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined'
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined'
import KeycloakService from '../../../../utils/keycloakService'
import { SUPER_ADMIN_DELETE } from '../../../../utils/roles'
import '../../../administration/admin.scss'
import { DeleteConfirmationDialog } from './DeleteConfirmationDialog'
import { Archive } from './Archive'
import { activeText } from '../../../Radiology/Constants/constants'

const PackagesTable = ({ data, onEdit }: AdminTableProps) => {
  const searchParam = useAppSelector(selectedLmsAdminSearch)
  const status = useAppSelector(selectedLmsStatus)
  const [tableData, setTableData] = useState(data)
  const [expandedRows, setExpandedRows] = useState<Set<number>>(new Set())

  const packagesTableContainerRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    const resizeHandler = () => {
      const container = packagesTableContainerRef.current
      if (container) {
        const availableHeight: any =
          window.innerHeight - container.getBoundingClientRect().top
        container.style.maxHeight = `${availableHeight - 20}px`
      }
    }

    window?.addEventListener('resize', resizeHandler)
    resizeHandler()

    return () => {
      window?.removeEventListener('resize', resizeHandler)
    }
  }, [])

  const toggleRow = (rowIndex: number) => {
    setExpandedRows((prev) => {
      const newExpandedRows = new Set(prev)
      if (newExpandedRows?.has(rowIndex)) {
        newExpandedRows?.delete(rowIndex)
      } else {
        newExpandedRows?.add(rowIndex)
      }
      return newExpandedRows
    })
  }
  const renderLabTests = (labTests: LabTests[], rowIndex: number) => {
    const initialItemCount = 2
    const isExpanded = expandedRows?.has(rowIndex)

    return (
      <div>
        {labTests
          ?.slice(0, isExpanded ? labTests?.length : initialItemCount)
          ?.map((item: LabTests) => (
            <li key={item?.id}>{item?.name}</li>
          ))}
        {labTests?.length > initialItemCount && (
          <span onClick={() => toggleRow(rowIndex)} className='linkbtns'>
            {isExpanded ? '...View Less' : '...View More'}
          </span>
        )}
      </div>
    )
  }
  useEffect(() => {
    if (Array?.isArray(data) && data?.length > 0) {
      const updatedData = data?.map((pkg) => {
        const activeTill = pkg?.active_till ? new Date(pkg?.active_till) : null
        const today = new Date()
        today.setHours(0, 0, 0, 0)
        if (
          !activeTill ||
          (activeTill >= today && pkg?.status === Status.Active)
        ) {
          return { ...pkg, status: Status.Active }
        }
        if (activeTill < today && pkg?.status === Status.Active) {
          return { ...pkg, status: Status.Inactive }
        }
        return pkg
      })
      setTableData(updatedData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  let filtered = getSearchResults(tableData, searchParam)

  filtered = filtered?.filter((d) => d?.status === status)

  enum Status {
    Active = 'active',
    Inactive = 'inactive',
  }

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [selectedPackage, setSelectedPackage] = useState<Package | null>(null)

  const handleOpenDeleteDialog = (pkg: Package) => {
    setSelectedPackage(pkg)
    setDeleteDialogOpen(true)
  }
  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false)
    setSelectedPackage(null)
  }

  return (
    <div
      className='data-table table-fixed admin-table-table-container'
      ref={packagesTableContainerRef}
    >
      <table className='data-table table-fixed admin-table relative'>
        <thead style={{ position: 'sticky', top: '0px' }}>
          <tr>
            <th className='table-w-5'>{PackageTableHeading?.ThopId}</th>
            <th className='table-w-5'>{PackageTableHeading?.OdooId}</th>
            <th className='table-w-15'>{PackageTableHeading?.PackageName}</th>
            <th className='table-w-20'>{PackageTableHeading?.TestName}</th>
            <th className='table-w-5'>{PackageTableHeading?.TestCount}</th>
            <th className='table-w-5'>{PackageTableHeading?.Price}</th>
            <th className='table-w-15'>{PackageTableHeading?.StartEnd}</th>
            <th className='table-w-10'>{PackageTableHeading?.Created}</th>
            <th className='table-w-10'>{PackageTableHeading?.Updated}</th>
            <th className='table-w-6'>{PackageTableHeading?.Actions}</th>
          </tr>
        </thead>
        <tbody>
          {filtered?.length ? (
            filtered?.map((d: any, index: any) => {
              const allLabTests =
                d?.panels?.flatMap((panel: any) => panel?.lab_tests) || []
              const testCount =
                (d?.lab_tests?.length ?? 0) + allLabTests?.length

              return (
                <tr key={d?.id}>
                  <td>{d?.id}</td>
                  <td>{d?.package_id}</td>
                  <td>
                    <LinkButton
                      id={d?.id}
                      name={d?.name}
                      onClick={() => onEdit(d?.id)}
                    />
                  </td>
                  <td>{renderLabTests(allLabTests, index)}</td>
                  <td>{testCount}</td>
                  <td>{d?.price}</td>
                  <td>
                    {d?.active_from
                      ? new Date(d?.active_from).toLocaleDateString('en-US', {
                          year: 'numeric',
                          month: 'short',
                          day: '2-digit',
                        })
                      : 'N/A'}
                    {'  -  '}
                    {d?.active_till
                      ? new Date(d?.active_till).toLocaleDateString('en-US', {
                          year: 'numeric',
                          month: 'short',
                          day: '2-digit',
                        })
                      : 'N/A'}
                  </td>
                  <td className='text-center'>
                    <ProvenanceView
                      date={d?.created_at}
                      name={d?.created_by}
                      mode={create}
                    />
                  </td>
                  <td className='text-center'>
                    <ProvenanceView
                      date={d?.updated_at ?? d?.createdOn}
                      name={d?.updated_by ?? d?.createdBy}
                      mode={update}
                    />
                  </td>
                  <td>
                    <div className='set-action-icons'>
                      {d?.status === activeText ? (
                        <IconButton
                          title='Archive'
                          size='small'
                          onClick={() =>
                            Archive(d, setTableData, menuValues?.Packages)
                          }
                          disabled={
                            !KeycloakService?.hasRole([SUPER_ADMIN_DELETE])
                          }
                        >
                          <ArchiveOutlinedIcon fontSize='small' />
                        </IconButton>
                      ) : (
                        <IconButton
                          title='Un-Archive'
                          size='small'
                          onClick={() =>
                            Archive(d, setTableData, menuValues?.Packages)
                          }
                          disabled={
                            !KeycloakService?.hasRole([SUPER_ADMIN_DELETE])
                          }
                        >
                          <UnarchiveOutlinedIcon fontSize='small' />
                        </IconButton>
                      )}
                      <IconButton
                        title='Delete'
                        size='small'
                        onClick={() => handleOpenDeleteDialog(d)}
                        disabled={
                          !KeycloakService?.hasRole([SUPER_ADMIN_DELETE])
                        }
                      >
                        <DeleteOutlined fontSize='small' />
                      </IconButton>
                    </div>
                  </td>
                </tr>
              )
            })
          ) : (
            <tr>
              <td colSpan={8} className='text-center'>
                No Packages Configured
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {deleteDialogOpen && (
        <DeleteConfirmationDialog
          openDialog={deleteDialogOpen}
          closeDialog={handleCloseDeleteDialog}
          data={selectedPackage}
          name={selectedPackage?.name}
          setData={setTableData}
          item={menuValues?.Packages}
        />
      )}
    </div>
  )
}

export default PackagesTable
