import React, { useEffect, useState } from 'react'
import './user-management-form.css'
import { startSxpProxy } from '../../../utils/api'
import { ADMIN_PROJECT_ID, CHAT_PROJECT_ID } from '../../../utils/constants'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { IconButton } from '@mui/material'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import { usercreation } from '../../patients/constants'
import ToastMessage from '../../lms/components/ToastMessage'
import { toastOptions } from '../../lms/models'
import { forms, UserManagementMessages } from '../constants'
export interface PractitionerOption {
  display: string
  code: string
  name: string
  id: string
}

export interface KeycloakRole {
  id: string
  name: string
}

const Form = () => {
  const [locations, setLocations] = useState([])
  const [practitionerOptions, setPractitionerOptions] = useState<
    PractitionerOption[]
  >([])
  const [keycloakRoles, setKeycloakRoles] = useState<PractitionerOption[]>([])
  const navigate = useNavigate()
  const [isloading, setisLoading] = useState(false)
  const [formData, setFormData] = useState({
    prefix: 'mr',
    username: '',
    firstName: '',
    lastName: '',
    email: '',
    phonenumber: '',
    SelectedLocation: '',
    SelectedKeyclockRoles: [] as { id: string; name: string }[],
    SelectPractitionerRole: '',
    SelectPractitionerName: '',
    gender: 'male',
    isDoctor: false,
    isOdooUser: false,
  })
  const handleChange = (e: any) => {
    const { name, options, type, checked, value } = e.target
    if (type === 'checkbox') {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: checked,
      }))
    } else if (name === 'SelectedKeyclockRoles') {
      const selectedValues: { id: string; name: string }[] = []
      for (let i = 0; i < options.length; i++) {
        if (options[i].selected) {
          selectedValues?.push({
            id: options[i]?.value,
            name: options[i]?.textContent || '',
          })
        }
      }
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: selectedValues,
      }))
    } else {
      if (name === 'phonenumber') {
        const regex = /^[0-9]*$/
        if (!regex?.test(value) || value.length > 10) {
          return
        }
      }
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }))
      if (name === 'prefix') {
        let gender = ''
        switch (value) {
          case 'mr':
            gender = 'male'
            break
          case 'miss':
            gender = 'female'
            break
          case 'ms':
          case 'mrs':
            gender = 'female'
            break
          default:
            gender = 'male'
            break
        }
        setFormData((prevFormData) => ({
          ...prevFormData,
          gender: gender,
        }))
      }
    }
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    setisLoading(true)
    startSxpProxy(ADMIN_PROJECT_ID, 'UserCreation', formData)
      ?.then((data) => {
        if (data?.status === 204 || data?.status === 201) {
          toast?.success('User Created')
          navigate('/userdata')
        }
      })
      .catch((error) => {
        const errorMessage =
          error?.response?.data?.data?.errorMessage ??
          UserManagementMessages?.KeyclockErrorMsg

        toast(<ToastMessage message={errorMessage} />, {
          ...toastOptions,
          type: 'error',
        })
      })
      .finally(() => {
        setisLoading(false)
      })
  }

  useEffect(() => {
    startSxpProxy(CHAT_PROJECT_ID, 'getLocationsAPI', {})
      ?.then((data) => {
        if (data?.data?.entry) {
          const fetchedLocations = data?.data?.entry.map(
            (entry: { resource: { id: any; name: any; status: any } }) => ({
              id: entry?.resource.id,
              name: entry?.resource.name,
              status: entry?.resource.status,
            })
          )
          setLocations(fetchedLocations)
        }
      })
      .catch((err) => console.error('Error fetching locations:', err))
  }, [])
  useEffect(() => {
    startSxpProxy(ADMIN_PROJECT_ID, 'getValueSet', {})
      ?.then((data) => {
        if (data?.data) {
          setPractitionerOptions(data.data)
        }
      })
      .catch((err) => console.error('Error fetching locations:', err))
  }, [])

  useEffect(() => {
    startSxpProxy(ADMIN_PROJECT_ID, 'allRoles', {})
      ?.then((data) => {
        if (data?.data) {
          setKeycloakRoles(data.data)
        }
      })
      .catch((err) => console.error('Error fetching Keycloak roles:', err))
  }, [])
  const activeLocations = locations?.filter(
    (location: any) => location?.status === 'active'
  )
  const navigateBack = () => {
    navigate(-1)
  }

  return (
    <>
      <div className='package-container'>
        <IconButton
          onClick={navigateBack}
          className='mb25'
          sx={{ fontSize: 20, color: 'blue' }}
        >
          <KeyboardBackspaceIcon className='backspace-icon-search-view' />
        </IconButton>
        <span className='opdpatientdata'>{usercreation}</span>
      </div>
      <form className='full-usercreation-form' onSubmit={handleSubmit}>
        <div className='form-group inline'>
          <div style={{ display: 'flex' }}>
            <label htmlFor='username'>
              Username
              <span className='required_red'>*</span>:
            </label>
            <select
              id='prefix'
              name='prefix'
              value={formData?.prefix}
              onChange={handleChange}
              required
            >
              <option value='mr'>Mr</option>
              <option value='miss'>Miss</option>
              <option value='ms'>Ms</option>
              <option value='mrs'>Mrs</option>
              <option value='dr'>Dr</option>
            </select>

            <input
              type='text'
              id='username'
              name='username'
              value={formData?.username}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div className='form-group'>
          <label htmlFor='firstName'>
            First Name
            <span className='required_red'>*</span>:
          </label>
          <input
            type='text'
            id='firstName'
            name='firstName'
            value={formData?.firstName}
            onChange={handleChange}
            required
          />
        </div>
        <div className='form-group'>
          <label htmlFor='lastName'>
            Last Name
            <span className='required_red'>*</span>:
          </label>
          <input
            type='text'
            id='lastName'
            name='lastName'
            value={formData?.lastName}
            onChange={handleChange}
            required
          />
        </div>
        <div className='form-group'>
          <label htmlFor='gender'>
            Gender
            <span className='required_red'>*</span>:
          </label>
          <select
            id='gender'
            name='gender'
            value={formData?.gender}
            onChange={handleChange}
            required
          >
            <option value='male'>Male</option>
            <option value='female'>Female</option>
            <option value='other'>Other</option>
          </select>
        </div>
        <div className='form-group'>
          <label htmlFor='email'>
            Email
            <span className='required_red'>*</span>:
          </label>
          <input
            type='email'
            id='email'
            name='email'
            value={formData?.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className='form-group'>
          <label htmlFor='phoneNumber'>
            Phone Number
            <span className='required_red'>*</span>:
          </label>
          <input
            type='tel'
            id='phonenumber'
            name='phonenumber'
            value={formData?.phonenumber}
            onChange={handleChange}
            required
            inputMode='numeric'
            maxLength={10}
          />
        </div>
        <div className='form-group'>
          <label>Doctor:</label>
          <input
            type='checkbox'
            name='isDoctor'
            checked={formData?.isDoctor}
            onChange={handleChange}
            className='userform-checkbox'
            style={{ height: '20px' }}
          />
        </div>

        <div className='form-group'>
          <label htmlFor='location'>
            Location
            <span className='required_red'>*</span>:
          </label>
          <select
            id='SelectedLocation'
            name='SelectedLocation'
            value={formData?.SelectedLocation}
            onChange={handleChange}
            required
          >
            <option value=''>Select</option>
            {activeLocations?.map((location: any) => (
              <option key={location?.id} value={location?.id}>
                {location?.name}
              </option>
            ))}
          </select>
        </div>
        <div className='form-group'>
          <label htmlFor='keycloakRole'>
            Keycloak Role
            <span className='required_red'>*</span>:
          </label>
          <select
            id='SelectedKeyclockRoles'
            name='SelectedKeyclockRoles'
            // value={formData?.SelectedKeyclockRoles?.map((role) => role?.id)}
            onChange={handleChange}
            multiple
            className='userform-selectedRoles'
            style={{ height: '50px' }}
          >
            {keycloakRoles?.length > 0 &&
              keycloakRoles?.map((role: KeycloakRole) => (
                <option key={role.id} value={role.id}>
                  {role?.name}
                </option>
              ))}
          </select>
        </div>
        <div className='form-group'>
          <label htmlFor='PractitionerRole'>
            Practitioner Role
            <span className='required_red'>*</span>:
          </label>
          <select
            id='SelectPractitionerRole'
            name='SelectPractitionerRole'
            value={formData?.SelectPractitionerRole}
            onChange={handleChange}
            required
          >
            <option value=''>Select</option>
            {practitionerOptions?.map((option: any) => (
              <option key={option.code} value={option.code}>
                {option?.display}
              </option>
            ))}
          </select>
        </div>
        <div className='form-group'>
          <label>Create Odoo:</label>
          <input
            type='checkbox'
            name='isOdooUser'
            checked={formData?.isOdooUser}
            onChange={handleChange}
            className='userform-checkbox'
            style={{ height: '20px' }}
          />
        </div>
        <div className='button-container-form'>
          <button
            className='userform-submit-btn'
            type='submit'
            disabled={isloading}
          >
            {isloading ? forms?.Submitting : forms?.submit}
          </button>
        </div>
      </form>
    </>
  )
}

export default Form
