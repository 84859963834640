export type transferType = {
  wardId: string
  bedId: string
  transferReason: string
}
export type bed = {
  floorNumber: string
  bedNumber: string
  status: string
  roomDetail: string
  bedCategory: string
  patientId?: string
  patientName?: string
  gender?: string
  age?: any
}
export type WardDetail = {
  id: number
  ward_name: string
}
export type Location = {
  name: string
}
export type filterType = {
  patientUhid: string
  status: string
  ward: string
  category: string
}
export type wards = {
  id: number
  name: string
}
export type bedCategory = {
  id: number
  name: string
}
export type roomType = {
  id: number
  room_name: string
  room_number: string
  floor_number: string
  ward_id: number
  ward_detail: {
    ward_name: string
    id: number
  }
}
export type ipdPatients = {
  date_created: string
  date_changed: string
  patient_id: string
  assigned_doctor: string
  patient_registered_id: string
  id: number
  bedId: number
  bedStatus: string
  patientId: string
  patientName: string
  dateCreated: string
  dateStarted: string
  dateOfDischarge?: string
  bedNumber: string
  location: string
  patientMobile: string
  patientGender: string
  patientAge: string
  doctor_department: string
  created_by: string
  updated_by: string
  dateChanged: string
  createdBy: string
  updatedBy: string
}
export type BedType = {
  id: number
  patient_id: string
  patient_name: string
  bed_number: string
  bed_category: bedCategory
  status: string
  admission_date: string
  discharged_date: string
  room_detail: roomType
  location_id: string
  bed_patient_assignment_maps: any[]
}
export type bedFilter = {
  id: any
  patientId: string
  patientName: string
  roomDetail: string
  bedNumber: string
  bedCategory: any
  status: string
  roomId?: number
  admission: string
  admissionDate?: string
  dischargedDate: string
  ward?: number
  wardName: string
  floorNumber?: string
  roomNumber?: string
  roomName?: string
  locationId?: string
  bedPatientMapId?: number
  bedPatientId?: number
  bedPatientAssignedId?: string
  bedPatientStatus?: string
  bedPatientDischargeDate?: string
  gender?: string
  age?: any
  patientRegisteredId: any
  assignedDoctor?: any
  remarks?: string
  patientAssignmentMap?: any[]
}
export type updateBedType = {
  admissionDate: string
  patientId: string
  id: number
  bedMapId: number
  patientMapId: string
  patientFhirId: string
  patientName?: string
}

export const Encounter = 'Encounter'
export const Practitioner = 'Practitioner'
export const Finished = 'finished'
export const Completed = 'Completed'
export const Cancelled = 'cancelled'
export const bedLayout = 'Bed Layout'
export const create = 'create'
export const edit = 'edit'
export const selectDischargeDate = 'Select Discharge Date'
export const dischargePatient = 'Discharge Patient'
export const updatedBed = 'updated-bed'
export const selectDate = 'Select Date:'
export const selectReason = 'Select Reason:'
export const bedErrorMessages = {
  dischargePatientError:
    'Unable to fetch patient correctly.contact Administartor',
  bedUndefined: 'undefined',
}
export const bedNavigations = {
  bedIpd: '/bedIpd',
  bedIpdOverview: '/bedIpd/bedOverview',
}
export const stateValues = {
  finished: 'finished',
  discharged: 'Discharged',
}
export const toastSuccessMessages = {
  dischargedSuccessfully: 'Discharged successfully',
  encounterClosedSuccessfully: 'Encounter closed successfully',
  encounterFailure: 'Closing Encounter Failed!',
}
export const ipdButtons = {
  confirm: 'Confirm',
  cancel: 'Cancel',
}
export const reasonOptions = {
  lamaKey: 'LAMA',
  deathValue: 'death',
  deathKey: 'Death',
  referralValue: 'referral',
  referralKey: 'Referral',
  lamaValue: 'leave Against Medical Advice',
}
