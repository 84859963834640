import { useState } from 'react'
import {
  ipdButtons,
  ipdPatients,
  selectDate,
  toastSuccessMessages,
} from '../constants'
import {
  allocateApi,
  allocation,
  errorMessages,
  tableHeadText,
} from '../modals'
import { emptyString, noData } from '../../Radiology/Constants/constants'
import { useTheme } from '@emotion/react'
import useCustomStyles from '../../../utils/hooks/CustomStylesHook'
import { Button } from '@mui/material'
import { startSxpProxy } from '../../../utils/api'
import { IPD_PROJECT_ID } from '../../../utils/constants'
import { toast } from 'react-toastify'
import { readableDateFormat } from '../../../utils/dateUtils'

type Props = {
  onConfirm: (image: boolean) => void
  onClose: () => void
  dischargeAdmin: ipdPatients | null
  setUpdatedCount?: any
}
const styles = () => ({
  mainContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 10,
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
  },
  bedContent: {
    marginBottom: '10px',
    width: '100%',
  },
  selectDateContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  selectDateContent: {
    display: 'flex',
    gap: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonContent: {
    fontSize: '10px',
    textTransform: 'capitalize',
  },
})
const convertISOStringToDate = (isoString: any) => {
  const date = new Date(isoString)
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  return `${year}-${month}-${day}`
}
const AdminDischargeDateDialog = ({
  onConfirm,
  onClose,
  dischargeAdmin,
  setUpdatedCount,
}: Props) => {
  const theme = useTheme()
  const classes = useCustomStyles(styles, theme)
  const [selectedDate, setSelectedDate] = useState(
    dischargeAdmin?.dateOfDischarge
  )
  const [error, setError] = useState('')
  const admissionDate = dischargeAdmin?.dateStarted
    ? new Date(dischargeAdmin?.dateStarted)?.toISOString()?.split('T')?.[0]
    : emptyString
  const handleDateChange = (event: any) => {
    const selectedDateValue = event?.target?.value
    const currentDate = new Date()?.toISOString()?.split('T')?.[0]
    if (selectedDateValue > currentDate) {
      setError(errorMessages?.selectUpcomingDate)
    } else {
      setError('')
      setSelectedDate(selectedDateValue)
    }
  }
  const handleDischargeDate = () => {
    const state = {
      bedId: dischargeAdmin?.bedId,
      id: dischargeAdmin?.id,
      patientId: dischargeAdmin?.patientId,
      dischargeDate: selectedDate,
    }
    startSxpProxy(
      IPD_PROJECT_ID,
      allocateApi?.updateDischargeDateForAdmin,
      state
    )
      .then(() => {
        toast.success(toastSuccessMessages?.encounterClosedSuccessfully)
        setUpdatedCount((prevState: any) => prevState + 1)
        onConfirm(false)
      })
      .catch((err) => {
        console.log(err)
      })
  }
  return (
    <>
      <div className={classes?.mainContainer}>
        <table className='bed-table'>
          <thead>
            <tr>
              <th>{tableHeadText.bedNo}</th>
              <th className='text-left'>{tableHeadText.location}</th>
              <th className='text-center'>{tableHeadText.admission}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className='text-center'>{dischargeAdmin?.bedNumber}</td>
              <td className='text-center'>{dischargeAdmin?.location}</td>
              <td className='text-center'>
                {dischargeAdmin?.dateCreated
                  ? readableDateFormat(
                      new Date(dischargeAdmin?.dateCreated ?? '')
                    )
                  : noData}
              </td>
            </tr>
          </tbody>
        </table>
        <div className={classes?.bedContent}>
          <div>
            <span className='bed-font'>{allocation?.patientDetails}</span>
          </div>
          <div>
            <table className='bed-table'>
              <thead>
                <tr>
                  <th>{tableHeadText.uhid}</th>
                  <th className='text-center'>{tableHeadText.name}</th>
                  <th className='text-center'>{tableHeadText.age}</th>
                  <th className='text-center'>{tableHeadText.gender}</th>
                  <th className='text-center w22'>
                    {tableHeadText.doctorName}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className='text-center'>{dischargeAdmin?.patientId}</td>
                  <td className='text-center'>{dischargeAdmin?.patientName}</td>
                  <td className='text-center'>{dischargeAdmin?.patientAge}</td>
                  <td className='text-center'>
                    {dischargeAdmin?.patientGender}
                  </td>
                  <td className='text-center'>
                    {dischargeAdmin?.assigned_doctor}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className={classes?.selectDateContainer}>
          <div className={classes?.selectDateContent}>
            <span>{selectDate}</span>
            <input
              type='date'
              value={convertISOStringToDate(selectedDate)}
              min={admissionDate}
              max={new Date()?.toISOString()?.split('T')?.[0]}
              onChange={handleDateChange}
              placeholder='dd/mm/yyyy'
            />
            {error && <span>{error}</span>}
          </div>
        </div>
        <div className={classes?.buttonContainer}>
          <Button
            variant='contained'
            disabled={!selectedDate}
            onClick={handleDischargeDate}
            className={classes?.buttonContent}
          >
            {ipdButtons?.confirm}
          </Button>
          <Button
            variant='contained'
            onClick={onClose}
            className={classes?.buttonContent}
          >
            {ipdButtons?.cancel}
          </Button>
        </div>
      </div>
    </>
  )
}
export default AdminDischargeDateDialog
