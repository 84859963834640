import React, { useEffect, useRef, useState } from 'react'
import CreateButton from '../../../components/CreateButton'
import { startSxpProxy } from '../../../utils/api'
import { ADMIN_PROJECT_ID } from '../../../utils/constants'
import { useNavigate } from 'react-router-dom'
import EditButton from '../../../components/EditButton'
import '../admin.scss'
import { Practitioner, PractitionerRole, Provenance } from 'fhir/r4'
import { ProvenanceData } from '../../patients/patientsSlice'
import { getProvenanceData } from '../../patients/utils'
import ProvenanceView from '../../../components/ProvenanceView'
import { formValues, forms, intent, placeHolder, tableText } from '../constants'
import { FormPathologist } from './PathologistForm'
import { pathologist } from '../../patientRegstration/constants'
import { IconButton } from '@mui/material'
import KeycloakService from '../../../utils/keycloakService'
import { SUPER_ADMIN_DELETE } from '../../../utils/roles'
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined'
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined'
import DeleteOutlined from '@mui/icons-material/DeleteOutlined'
import { create, menuValues, update } from '../../lms/constants'
import { DeleteConfirmationDialog } from '../../lms/components/admin/DeleteConfirmationDialog'
import { Archive } from '../../lms/components/admin/Archive'
import { emptyString } from '../../Radiology/Constants/constants'

export const LabIncharge = () => {
  const [pathologistData, setPathologistData] = useState<FormPathologist[]>([])
  const [searchText, setSearchText] = useState<string>('')
  const [statusFilter, setStatusFilter] = useState('active')
  const navigate = useNavigate()
  const ids = pathologistData.map((p) => p.id).join(',')
  const [provenances, setProvenances] = useState<
    Record<string, ProvenanceData>
  >({})
  const [deleteDialog, setDeleteDialog] = useState<boolean>(false)
  const [pathologists, setPathologists] = useState<any>({})
  useEffect(() => {
    if (ids) {
      startSxpProxy(ADMIN_PROJECT_ID, intent?.fetchProvByDoctors, {
        pracIds: ids,
      })?.then((data) => {
        const entries =
          data?.data?.entry?.map(
            (dde: { resource: Provenance | Practitioner }) => dde?.resource
          ) ?? []
        const obj = getProvenanceData(entries)
        setProvenances(obj)
      })
    }
  }, [ids])
  const formatDoctors = (
    roles: { resource: PractitionerRole }[],
    id?: string
  ) => {
    if (!id) {
      return 'NA'
    }
    const resType = roles.find(
      (e) => e?.resource?.practitioner?.reference?.split?.('/')?.[1] === id
    )
    const code =
      resType?.resource?.specialty?.[0]?.coding?.[0]?.code ?? emptyString
    const display =
      resType?.resource?.specialty?.[0]?.coding?.[0]?.display ?? emptyString
    return `${display}::${code}`
  }
  const patientTableContainerRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    const resizeHandler = () => {
      const container = patientTableContainerRef.current
      if (container) {
        const availableHeight: any =
          window.innerHeight - container.getBoundingClientRect().top
        container.style.maxHeight = `${availableHeight - 60}px`
      }
    }
    window.addEventListener('resize', resizeHandler)
    resizeHandler()
    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])
  useEffect(() => {
    startSxpProxy(ADMIN_PROJECT_ID, intent?.getLabInchargeList)
      ?.then((res) => {
        const practitioners = res?.data?.entry?.filter(
          (e: { resource: { resourceType: string } }) =>
            e.resource?.resourceType === forms.practitioner
        )
        const roles: { resource: PractitionerRole }[] =
          res?.data?.entry?.filter(
            (e: { resource: { resourceType: string } }) =>
              e.resource?.resourceType === forms?.practitionerRole
          )
        const data: FormPathologist[] = practitioners?.map(
          (e: { resource: Practitioner }) => {
            const role = roles.find(
              (r) =>
                r.resource?.practitioner?.reference?.split?.('/')?.[1] ===
                e.resource.id
            )
            return {
              id: e.resource?.id,
              firstName:
                e.resource?.name?.[0]?.given?.join(emptyString) ?? emptyString,
              middleName: emptyString,
              lastName: e.resource?.name?.[0]?.family ?? emptyString,
              salutation: e.resource?.name?.[0]?.prefix?.[0] ?? emptyString,
              registeredOn:
                e.resource?.identifier?.[1]?.period?.start ?? emptyString,
              dob: e.resource?.birthDate,
              email:
                e?.resource?.telecom?.find(
                  (contact: any) => contact?.system === formValues.email
                )?.value || formValues.na,
              phone:
                e?.resource?.telecom?.find(
                  (contact: any) => contact?.system === formValues.phone
                )?.value || formValues.na,
              qualificationValue:
                e?.resource?.qualification?.[0]?.code?.text || formValues.na,
              gender: e?.resource?.gender || formValues.na,
              regNumber:
                e?.resource?.qualification?.[0]?.identifier?.[0]?.value,
              speciality:
                formatDoctors(roles, e?.resource?.id) || formValues.na,
              active: e?.resource?.active ?? true,
              healthService:
                role?.resource?.healthcareService?.map?.(
                  (hs) => hs.reference?.split?.('/')?.[1] ?? emptyString
                ) ?? [],
            }
          }
        )
        data?.sort((a, b) => (a.id < b.id ? -1 : 1))
        setPathologistData(data)
      })
      ?.catch((err) => console.error(err))
  }, [statusFilter])

  const filteredPathology = pathologistData
    ?.filter((pathologist) => {
      const isNameSearch =
        pathologist.firstName
          .toLowerCase()
          .startsWith(searchText.toLowerCase()) ||
        pathologist.lastName.toLowerCase().startsWith(searchText.toLowerCase())

      const isStatusMatched =
        statusFilter === formValues.all ||
        (statusFilter === formValues.active && pathologist.active) ||
        (statusFilter === formValues.inActive && !pathologist.active)

      return isNameSearch && isStatusMatched
    })
    ?.filter((pathologist) => {
      return (
        pathologist.firstName?.trim() !== emptyString ||
        pathologist.lastName?.trim() !== emptyString
      )
    })
  filteredPathology?.sort?.(
    (a: { firstName: string }, b: { firstName: string }) =>
      a.firstName?.toLowerCase() < b.firstName?.toLowerCase() ? -1 : 1
  )

  const handleCreate = () => {
    navigate('create')
  }

  const handleEdit = (id?: string) => {
    if (id) {
      navigate(`edit/${id}`)
    }
  }

  const handleStatusFilterChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setStatusFilter(event.target.value)
  }

  function handleDeleteDialogOpen(d: any) {
    setDeleteDialog(true)
    setPathologists(d)
  }

  function handleCloseDeleteDialog() {
    setDeleteDialog(false)
    setPathologists(null)
  }
  return (
    <>
      <div className='button-enclosure flexible header-bottom'>
        <div className='filterandtext'>
          <div className='membershipText'>{pathologist}</div>
        </div>
        <div className='status-flex'>
          <select
            value={statusFilter}
            onChange={handleStatusFilterChange}
            className='status-filter doctor-status'
          >
            <option value={formValues?.active}>{forms?.active}</option>
            <option value={formValues?.inActive}>{forms?.inactive}</option>
          </select>
          <input
            type='search'
            className='admin-search mr-none'
            value={searchText}
            onChange={({ target: { value } }) => setSearchText(value)}
            placeholder={placeHolder.pathologyName}
          />
          <CreateButton title={'Create'} onClick={handleCreate} />
        </div>
      </div>
      <div
        ref={patientTableContainerRef}
        className='data-table table-fixed admin-table-table-container overflow-y'
      >
        <table className='data-table table-fixed admin-table relative'>
          <thead className='position-top'>
            <tr>
              <th className='table-w-8'>{tableText?.slNo}</th>
              <th className='text-left table-w-25'>{tableText?.name}</th>
              <th className='text-left table-w-20'>{tableText?.speciality}</th>
              <th className='text-left table-w-20'>
                {tableText?.qualification}
              </th>
              <th className='text-left table-w-20'>{tableText?.regNo}</th>
              <th className='table-w-20'>{tableText?.email}</th>
              <th className='text-left table-w-20'>{tableText?.phone}</th>
              <th className='text-left table-w-15'>{tableText?.gender}</th>
              <th className='text-left table-w-23'>
                {tableText?.operatingUnit}
              </th>
              <th className='text-left table-w-20'>{tableText?.createdBy}</th>
              <th className='text-left table-w-20'>{tableText?.updatedBy}</th>
              <th className='text-left table-w-15'>{tableText?.action}</th>
            </tr>
          </thead>
          <tbody>
            {filteredPathology?.length > 0 ? (
              filteredPathology?.map((user, index) => (
                <tr key={index}>
                  <td className='table-w-4'>{index + 1}</td>
                  <td className='text-left table-w-15'>{`${user?.salutation} ${user?.firstName} ${user?.lastName}`}</td>
                  <td className='text-left table-w-20'>
                    {user?.speciality.split('::')[0]}
                  </td>
                  <td className='text-center table-w-20'>
                    {user?.qualificationValue}
                  </td>
                  <td className='text-left table-w-10 wordBreak'>
                    {user?.regNumber}
                  </td>
                  <td className='table-w-40 wordBreak normal'>{user?.email}</td>
                  <td className='text-left table-w-15'>{user?.phone}</td>
                  <td className='text-left'>{user?.gender}</td>
                  <td className='text-center'>-</td>
                  <td className='text-center'>
                    <ProvenanceView
                      date={provenances?.[user?.id]?.createdOn}
                      name={provenances?.[user?.id]?.createdBy}
                      mode={create}
                    />
                  </td>
                  <td>
                    <ProvenanceView
                      date={provenances?.[user?.id]?.updatedOn}
                      name={provenances?.[user?.id]?.updatedBy}
                      mode={update}
                    />
                  </td>
                  <td className='edit-relation'>
                    <div className='set-action-icons'>
                      {user?.active ? (
                        <IconButton
                          title='Archive'
                          size='small'
                          onClick={() =>
                            Archive(
                              user,
                              setPathologistData,
                              menuValues?.Pathologist
                            )
                          }
                          disabled={
                            !KeycloakService?.hasRole([SUPER_ADMIN_DELETE])
                          }
                        >
                          <ArchiveOutlinedIcon fontSize='small' />
                        </IconButton>
                      ) : (
                        <IconButton
                          title='un-Archive'
                          size='small'
                          onClick={() =>
                            Archive(
                              user,
                              setPathologistData,
                              menuValues?.Pathologist
                            )
                          }
                          disabled={
                            !KeycloakService?.hasRole([SUPER_ADMIN_DELETE])
                          }
                        >
                          <UnarchiveOutlinedIcon fontSize='small' />
                        </IconButton>
                      )}
                      <IconButton
                        title='Delete'
                        onClick={() => handleDeleteDialogOpen(user)}
                        disabled={
                          !KeycloakService?.hasRole([SUPER_ADMIN_DELETE])
                        }
                      >
                        <DeleteOutlined />
                      </IconButton>
                      <EditButton
                        title='Edit Pathologist'
                        onEdit={() => handleEdit(user?.id)}
                      />
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={11} className='text-center'>
                  {tableText.noPathologistFound}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {deleteDialog && (
        <DeleteConfirmationDialog
          openDialog={deleteDialog}
          closeDialog={handleCloseDeleteDialog}
          data={pathologists}
          name={pathologists?.firstName + ' ' + pathologists?.lastName}
          setData={setPathologistData}
          item={menuValues?.Pathologist}
        />
      )}
    </>
  )
}

export default LabIncharge
