import { useTheme } from '@emotion/react'
import useCustomStyles from '../../../utils/hooks/CustomStylesHook'
import {
  Box,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  TextField,
  FormHelperText,
  Button,
} from '@mui/material'
import { constants, messages } from '../Constants'
import { useAppSelector } from '../../../app/hooks'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { selectLocations } from '../../location/locationSlice'
import { editProrithmMapping } from '../utils'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { setAddDeviceCount } from '../ProrithmSlice'

const styles = () => ({
  proRithmDasboardContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    padding: '2%',
    gap: 20,
  },
})

interface MapToAmbulanceFormProps {
  selectedDeviceId: string
  handleMappingDialogClose: () => void
}

const MapToAmbulanceForm: React.FC<MapToAmbulanceFormProps> = ({
  selectedDeviceId,
  handleMappingDialogClose,
}) => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const classes = useCustomStyles(styles, theme)
  const locations = useAppSelector(selectLocations)

  const handleDeviceMapping = async () => {
    const mappingStatus = constants?.ambulanceText
    const selectedLocation = formik?.values?.location

    const ambulanceDetails = {
      ambulanceNumber: formik?.values?.ambulanceNumber,
      location: selectedLocation,
      driverPhone: formik?.values?.driverPhone,
      emtPhone: formik?.values?.emtPhone,
      status: formik?.values?.status,
    }

    const success = await editProrithmMapping(
      mappingStatus,
      selectedDeviceId,
      selectedLocation,
      undefined,
      ambulanceDetails
    )

    if (success) {
      dispatch(setAddDeviceCount())
      toast(messages?.deviceMappedSuccessfully, {
        position: 'bottom-center',
        hideProgressBar: true,
        closeButton: false,
        theme: 'dark',
        autoClose: 4000,
      })
    }

    handleMappingDialogClose()
  }

  const formik = useFormik({
    initialValues: {
      ambulanceNumber: '',
      location: '',
      driverPhone: '',
      emtPhone: '',
      status: '',
    },
    validationSchema: Yup?.object({
      ambulanceNumber: Yup?.string()
        ?.matches(/^[a-zA-Z0-9]+$/, messages?.ambulanceNumberInvalid)
        ?.required(messages?.ambulanceNumberRequired),
      location: Yup?.string()?.required(messages?.locationRequired),
      driverPhone: Yup?.string()
        ?.matches(/^\d{10}$/, messages?.driverPhoneInvalid)
        ?.required(messages?.driverNumberRequired),
      emtPhone: Yup.string()
        ?.matches(/^\d{10}$/, messages?.emtPhoneInvalid)
        ?.required(messages?.emtNumberRequired),
      status: Yup?.string()?.required(messages?.statusRequired),
    }),

    onSubmit: () => {
      handleDeviceMapping()
    },
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box display='flex' flexDirection='row' flexWrap='wrap'>
        <Box minWidth={220} marginY={2} marginX={1}>
          <TextField
            required
            fullWidth
            type='text'
            margin='dense'
            id='ambulanceNumber'
            label='Ambulance number'
            name='ambulanceNumber'
            value={formik?.values?.ambulanceNumber}
            onChange={formik?.handleChange}
            onBlur={formik?.handleBlur}
            error={
              formik?.touched?.ambulanceNumber &&
              Boolean(formik?.errors?.ambulanceNumber)
            }
            helperText={
              formik?.touched?.ambulanceNumber &&
              formik?.errors?.ambulanceNumber
            }
          />
        </Box>
        <Box minWidth={220} marginY={3}>
          <FormControl
            fullWidth
            error={
              formik?.touched?.location && Boolean(formik?.errors?.location)
            }
          >
            <InputLabel>{constants?.selectLocation}</InputLabel>
            <Select
              id='location-select'
              name='location'
              label={constants?.selectLocation}
              value={formik?.values?.location}
              onChange={formik?.handleChange}
              onBlur={formik?.handleBlur}
              className={classes?.SelectLayout}
            >
              {locations
                ?.filter((l) => l?.resource?.status === constants?.activeStatus)
                ?.map((d) => (
                  <MenuItem key={d?.resource?.id} value={d?.resource?.name}>
                    {d?.resource?.name}
                  </MenuItem>
                ))}
            </Select>
            {formik?.touched?.location && formik?.errors?.location && (
              <FormHelperText>{formik?.errors?.location}</FormHelperText>
            )}
          </FormControl>
        </Box>
        <Box minWidth={220} marginY={2} marginX={1}>
          <TextField
            fullWidth
            type='text'
            margin='dense'
            id='driverPhone'
            label='Driver phone number'
            name='driverPhone'
            value={formik?.values?.driverPhone}
            onChange={formik?.handleChange}
            onBlur={formik?.handleBlur}
            error={
              formik?.touched?.driverPhone &&
              Boolean(formik?.errors?.driverPhone)
            }
            helperText={
              formik?.touched?.driverPhone && formik?.errors?.driverPhone
            }
          />
        </Box>
        <Box minWidth={220} marginY={2}>
          <TextField
            fullWidth
            type='text'
            margin='dense'
            id='emtPhone'
            label='EMT phone number'
            name='emtPhone'
            value={formik?.values?.emtPhone}
            onChange={formik?.handleChange}
            onBlur={formik?.handleBlur}
            error={
              formik?.touched?.emtPhone && Boolean(formik?.errors?.emtPhone)
            }
            helperText={formik?.touched?.emtPhone && formik?.errors?.emtPhone}
          />
        </Box>
        <Box minWidth={220} marginY={3} marginX={1}>
          <FormControl
            fullWidth
            error={formik?.touched?.status && Boolean(formik?.errors?.status)}
          >
            <InputLabel>{constants?.status}</InputLabel>
            <Select
              label='Status'
              id='status'
              name='status'
              value={formik?.values?.status}
              onChange={formik?.handleChange}
              onBlur={formik?.handleBlur}
            >
              <MenuItem value='ACTIVE'>{constants?.active}</MenuItem>
              <MenuItem value='INACTIVE'>{constants?.inactive}</MenuItem>
            </Select>
            {formik?.touched?.status && formik?.errors?.status && (
              <FormHelperText>{formik?.errors?.status}</FormHelperText>
            )}
          </FormControl>
        </Box>
      </Box>
      <Box marginY={2} display='flex' justifySelf='flex-end' maxWidth={200}>
        <Button onClick={() => handleMappingDialogClose()}>
          {constants?.close}
        </Button>
        <Button type='submit'>{constants?.submit}</Button>
      </Box>
    </form>
  )
}

export default MapToAmbulanceForm
