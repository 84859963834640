import React, { useMemo, useCallback, useRef, useState } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { ColDef, GridReadyEvent } from 'ag-grid-community'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import {
  IconButton,
  Tooltip,
  useTheme,
  TablePagination,
  Box,
} from '@mui/material'
import useCustomStyles from '../../utils/hooks/CustomStylesHook'
import GetAppOutlinedIcon from '@mui/icons-material/GetAppOutlined'
import { saveAs } from 'file-saver'
import * as XLSX from 'xlsx'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { addProrithmDevice } from '../../features/ProRithm/utils'
import { constants } from '../../features/ProRithm/Constants'
import { setAddDeviceCount } from '../../features/ProRithm/ProrithmSlice'
import { useAppDispatch } from '../../app/hooks'

const styles = () => ({
  MedAgDataGridContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  },
  MedAgDataGridQuickFilterContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  MedAgDataGridInput: {
    border: '1px solid grey',
    borderRadius: 5,
    padding: '5px !important',
  },
  MedAgDataGridExportIcon: {
    background: '#4682b4',
    color: '#ffffff',
    borderRadius: 5,
  },
  MedAgDataGridFilterContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 10,
  },
  MedAgDataGridheading: {
    color: '#090968',
    fontSize: '16px',
    fontWeight: 600,
  },
  MedAgDataGridPaginationContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'center',
    fontSize: '12px !important',
    background: '#fff',
    borderBottom: '1px solid #babfc7',
    borderLeft: '1px solid #babfc7',
    borderRight: '1px solid #babfc7',
    height: '35px',
  },
  SelectLayout: {
    height: '33px',
  },
  LightBlue: {
    background: '#4682b4',
  },
  DeviceName: {
    textDecoration: 'underline',
    fontSize: '15px',
    cursor: 'pointer',
  },
  SearchBar: {
    border: '1px solid grey',
    borderRadius: 5,
    padding: '5px',
    width: '200px',
    height: '32px',
  },
})

interface DataGridProps<T> {
  tableHeight: any
  rowData: T[]
  columnDefs: ColDef[]
  pagination: boolean
  pageSizeOptions?: number[]
  defaultPageSize?: number
  page?: number
  setPage?: (page: number) => void
  pageSize?: number
  setPageSize?: (pageSize: number) => void
  totalRows?: number
  headerHeight?: number
  tableHeading?: string
  filterActions?: React.ReactNode
  searchFieldWidth?: string | number
  searchFieldPlaceHolder?: string
  searchField?: boolean
  exportIcon?: boolean
  [key: string]: any
}

const ProRithmAgDataGrid = <T extends object>({
  tableHeight,
  rowData,
  columnDefs,
  pagination,
  pageSizeOptions = [20, 40, 60],
  page = 0,
  setPage,
  pageSize = 20,
  setPageSize,
  totalRows = 0,
  headerHeight,
  tableHeading,
  searchFieldWidth,
  searchFieldPlaceHolder,
  searchField = true,
  exportIcon = true,
  ...rest
}: DataGridProps<T>) => {
  const dispatch = useAppDispatch()
  const [openAddDeviceDialog, setOpenAddDeviceDialog] = useState<boolean>(false)
  const [deviceId, setDeviceId] = useState<string>('')
  const [deviceIdErrorText, setDeviceIdErrorText] = useState<string>('')
  const theme = useTheme()
  const classes = useCustomStyles(styles, theme)
  const gridRef = useRef<AgGridReact<T>>(null)
  const defaultColDef = useMemo<ColDef>(
    () => ({
      //   floatingFilter: true,
      resizable: true,
      //   autoHeaderHeight: true,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const onGridReady = useCallback((params: GridReadyEvent) => {
    params.api.sizeColumnsToFit()
  }, [])

  const onFilterTextBoxChanged = useCallback(() => {
    const gridApi = gridRef?.current?.api
    const filterTextBoxValue = (
      document.getElementById('filter-text-box') as HTMLInputElement
    )?.value

    if (gridApi && filterTextBoxValue !== undefined) {
      gridApi.setGridOption('quickFilterText', filterTextBoxValue)
    }
  }, [])

  const exportExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(rowData)
    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    })

    saveAsExcelFile(excelBuffer, 'Data')
  }

  const saveAsExcelFile = (buffer: any, fileName: string) => {
    const EXCEL_TYPE =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const EXCEL_EXTENSION = '.xlsx'
    const data = new Blob([buffer], { type: EXCEL_TYPE })
    saveAs(data, `${fileName}_export_${new Date().getTime()}${EXCEL_EXTENSION}`)
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (setPage) setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (setPage) setPage(0)
    if (setPageSize) setPageSize(parseInt(event.target.value))
  }

  const closeAddDeviceDialog = () => {
    setOpenAddDeviceDialog(false)
    setDeviceId('')
    setDeviceIdErrorText('')
  }

  const handleAddDevice = () => {
    setOpenAddDeviceDialog(false)
    addProrithmDevice(deviceId)
    setDeviceId('')
    dispatch(setAddDeviceCount())
  }

  const handleDeviceNameChange = (value: string) => {
    const deviceRegex = /([A-Za-z0-9]{2}:){5}[A-Za-z0-9]{2}/
    setDeviceId(value)

    if (!deviceRegex.test(value)) {
      setDeviceIdErrorText(constants?.enterValidDeviceId)
    } else {
      setDeviceIdErrorText('')
    }
  }

  return (
    <Box
      className={classes?.MedAgDataGridContainer}
      height={tableHeight}
      width='100%'
    >
      <div className={classes?.MedAgDataGridQuickFilterContainer}>
        <div className={classes?.MedAgDataGridheading}>{tableHeading}</div>
        <div className={classes?.MedAgDataGridFilterContainer}>
          <Button
            size='small'
            id='add-device-btn'
            variant='contained'
            endIcon={<AddIcon />}
            className={classes?.LightBlue}
            onClick={() => setOpenAddDeviceDialog(true)}
          >
            {constants?.addDevice}
          </Button>
          {exportIcon ? (
            <IconButton onClick={exportExcel}>
              <Tooltip title='Export'>
                <GetAppOutlinedIcon
                  className={classes?.MedAgDataGridExportIcon}
                />
              </Tooltip>
            </IconButton>
          ) : null}
          {searchField ? (
            <input
              type='text'
              id='filter-text-box'
              placeholder={searchFieldPlaceHolder ?? 'Search...'}
              onInput={onFilterTextBoxChanged}
              className={classes?.MedAgDataGridInput}
              style={{ width: searchFieldWidth }}
            />
          ) : null}
        </div>
      </div>
      <Box className='ag-theme-alpine' height='100%' width='100%'>
        <AgGridReact
          ref={gridRef}
          headerHeight={headerHeight}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          pagination={false}
          onGridReady={onGridReady}
          {...rest}
        />
        {pagination && (
          <div className={classes?.MedAgDataGridPaginationContainer}>
            <TablePagination
              variant='footer'
              size='small'
              component='div'
              count={totalRows}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={pageSize}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={pageSizeOptions}
            />
          </div>
        )}
      </Box>
      <Dialog
        id='add-device-dialog'
        open={openAddDeviceDialog}
        onClose={closeAddDeviceDialog}
      >
        <DialogTitle>{constants?.addDevice}</DialogTitle>
        <DialogContent>
          <TextField
            required
            fullWidth
            autoFocus
            type='text'
            margin='dense'
            value={deviceId}
            variant='standard'
            id='new-device-id'
            label='Enter device id'
            onChange={(e) => handleDeviceNameChange(e.target.value)}
            helperText={constants?.deviceIdHelperText}
          />
          {deviceIdErrorText?.length > 0 && (
            <Box color='red'>{deviceIdErrorText}</Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button id='add-device-close-btn' onClick={closeAddDeviceDialog}>
            {constants?.close}
          </Button>
          <Button
            type='submit'
            id='add-device-submit-btn'
            disabled={deviceId?.length === 0 || deviceIdErrorText?.length > 0}
            onClick={handleAddDevice}
          >
            {constants?.submit}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default ProRithmAgDataGrid
